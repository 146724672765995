import UserProfile from "@/model/UserProfile";

export enum UserStatus {
    UNCONFIRMED = 'UNCONFIRMED',
    CONFIRMED = 'CONFIRMED',
    ARCHIVED = 'ARCHIVED',
    COMPROMISED = 'COMPROMISED',
    UNKNOWN = 'UNKNOWN',
    RESET_REQUIRED = 'RESET_REQUIRED',
    FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
}

export default interface User {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    status?: UserStatus,
    enabled?: boolean

    userProfile?: UserProfile,
}

export interface UserCreateResponse {
    user: User,
    errorAssigningRole?: string,
    errorAssociatingToClients?: string
}
