<!--suppress ALL -->
<template>
  <div>
    <SnackbarMessage v-model="showError" error>{{ this.error }}</SnackbarMessage>
    <SnackbarMessage v-model="showSuccessMessage">{{successMessage}}</SnackbarMessage>
    <v-data-table
        :headers="headers"
        :items="reports"
        align="start"
        :loading="loadingAllReports"
        must-sort
        class="elevation-1 report-table"
        :footer-props="{itemsPerPageOptions: [10, 15, 25, 50, 100] }"
        :item-class="(item) => rowSelected === item ? 'selected' : ''"
        mobile-breakpoint="750"
        :server-items-length="totalCount"
        :options="pagingOptions"
        @update:options="$emit('update:pagingOptions', $event)"
    >
      <template v-if="canCreateIntelReport(userProfile)" v-slot:[`item.actions`]="{isMobile, item}">
        <div v-if="isMobile" class="mt-3">
          <v-btn class="mr-2" @click="editItem(item)" ><v-icon left>mdi-pencil</v-icon>Edit</v-btn>
          <v-btn @click="deleteItem(item)"><v-icon left>mdi-delete</v-icon>Delete</v-btn>
        </div>
        <v-menu v-else right :offset-y="true">
          <template v-slot:[`activator`]="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon small color="primary" data-test="report-options-btn">
              <v-icon small>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editItem(item)" data-test="edit-btn">
              <v-list-item-icon class="mr-1"><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
              <v-list-item-title>Edit report</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteItem(item)" data-test="delete-btn">
              <v-list-item-icon class="mr-1"><v-icon small>mdi-delete</v-icon></v-list-item-icon>
              <v-list-item-title>Delete report</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`item.title`]="{item}">
        <v-tooltip left nudge-bottom="30" nudge-right="150">
          <template v-slot:[`activator`]="{ on, attrs }">
            <div class="d-flex">
              <span class="pr-1" v-bind="attrs" v-on="on" >
                <DownloadLink :name="item.title" :id="item.id" file-type="report">
                  <v-icon small color="primary">mdi-download</v-icon>
                </DownloadLink>
              </span>
              <span v-bind="attrs" v-on="on" >
                <DownloadLink :name="item.title" :id="item.id" file-type="report">
                  {{item.title}}
                </DownloadLink>
              </span>
            </div>
          </template>
          <span>{{item.filename}}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.displayNum`]="{item}">
        <v-tooltip bottom>
          <template v-slot:[`activator`]="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <span class="text-no-wrap" v-if="reportNumber(item)">{{reportNumber(item)}}</span>
            </div>
          </template>
          <span>
            <span v-if="isBroadcast(item)">Broadcast - Report {{item.displayNum}}</span>
            <span v-else>{{item.clientDisplayName}} - Report {{item.displayNum}}</span>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.reportType`]="{item}">
        <span class="enum-val">{{ getReportTypeText(item.reportType) }}</span>
      </template>
      <template v-slot:[`item.precedence`]="{item}">
        <span class="enum-val">{{ findPrecedence(item.precedenceId) }}</span>
      </template>
      <template v-slot:[`item.filename`]="{item}">
        <DownloadLink :name="item.filename" :id="item.id" file-type="report"/>
      </template>
      <template v-slot:[`item.releasedAt`]="{item}">
        <div class="text-no-wrap">
          {{ formatDate(item.releasedAt) }}
        </div>
      </template>
      <template v-slot:[`item.createdAt`]="{item}">
        <div class="text-no-wrap">
          {{ formatDate(item.createdAt) }}
        </div>
      </template>
      <template v-slot:[`item.clientDisplayName`]="{item}">
        <span v-if="item.clientDisplayName">{{ item.clientDisplayName }}</span>
        <span v-else class="grey--text">ALL</span>
      </template>
      <template v-slot:[`item.reportStatus`]="{item}">
        <v-menu
            v-if="showStatusUpdateMenu(item)"
            bottom
            @input="handleMenuClick($event, item)"
        >
          <template v-slot:[`activator`]="{ on, attrs }">
            <a
                href="#"
                class="status-link text-no-wrap"
                v-bind="attrs"
                v-on="on"
                @click.stop.prevent
                data-test="reportStatusShowAnchor"
            >
              {{ getReportStatus(item.reportStatus) }}
              <v-icon
                  small
                  color="primary"
              >
                mdi-chevron-down
              </v-icon>
              <v-progress-circular
                  v-if="savingReport.saving && savingReport.reportId === item.id"
                  color="primary"
                  size="15"
                  width="2"
                  indeterminate
              />
            </a>
          </template>
          <v-list>
            <v-list-item-group
                v-model="item.reportStatus"
                color="primary"
                @change="updateReportStatus(item)"
            >
              <v-list-item
                  v-for="(status) in reportStatusOpts"
                  :key="status.key"
                  :value="status.key"
                  :disabled="disableReportStatus(item, status.key)"
              >
                <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
                <v-list-item-title v-text="status.value"/>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <div v-else data-test="reportStatusNoShowAnchor"
        >
          {{ getReportStatus(item.reportStatus) }}
        </div>
      </template>

      <template v-slot:[`item.assignments`]="{item}">
        <div v-if="isBroadcast(item)" class="font-weight-light" data-test="assignments">N/A</div>
        <ReportAssignments v-else :report="item" @saved="handleAssignmentSave(item, $event)" @editing="handleEditingAssignment(item, $event)"/>
      </template>
    </v-data-table>

    <v-dialog v-if="showNewReportForm" v-model="showNewReportForm" persistent max-width="800">
      <NewReportForm
          :editReport="editReport"
          :editMode="true"
          @cancel="showNewReportForm = false"
          @updated="reportUpated"
      />
    </v-dialog>
    <v-dialog v-model="showDeleteConfirmation" persistent max-width="250">
      <v-card>
        <v-card-title class="text-h5">
          Confirm Delete
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete the INTEL report <b>{{ deleteReportItem ? deleteReportItem.title : '' }}</b>?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              type="button" :disabled="savingReport.saving"
              @click="showDeleteConfirmation = false"
          >
            Cancel
          </v-btn>
          <v-btn
              color="primary" type="button" :loading="savingReport.saving"
              @click="deleteReport()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue, {PropType} from "vue";
import {DataLoadStatus} from "@/store";
import ReportPrecedence from "@/model/ReportPrecedence";
import IntelReport from "@/model/IntelReport"
import DownloadLink from "@/components/DownloadLink.vue";
import ReportStatus, {reportStatusOptions} from "@/model/ReportStatus";
import reportService from "@/services/ReportService";
import SnackbarMessage from "@/components/SnackbarMessage.vue";
import AuthHelper from "@/services/AuthHelper";
import {mapGetters, mapState} from "vuex";
import ReportType, {reportTypeOptions} from "@/model/ReportType";
import NewReportForm from "@/components/NewReportForm.vue";
import {formatIsoDateToMmDdYyyy} from "@/services/view-helpers";
import ReportAssignments from "@/components/ReportAssignments.vue";
import UserProfile from "@/model/UserProfile";
import PagingOptions from "@/model/PagingOptions";

interface Header {
  text: string,
  value: string,
  class?: string,
  sortable?: boolean,
  filterable?: boolean,
  width?: string
}
export default Vue.extend({
  name: "ReportList",
  components: {
    ReportAssignments,
    DownloadLink,
    SnackbarMessage,
    NewReportForm,
  },
  props: {
    loadingAllReports: Boolean,
    reports: Array as PropType<IntelReport[]>,
    totalCount: Number,
    pagingOptions: Object as PropType<PagingOptions>,
  },
  data() {
    return {
      error: '',
      showError: false,
      showSuccessMessage: false,
      successMessage: '',
      showNewReportForm: false,
      showDeleteConfirmation: false,
      editReport: null as (IntelReport | null),
      editedIndex: -1,
      deleteReportItem: null as (IntelReport | null),
      reportStatus: null as (ReportStatus | null),
      savingReport: {
        saving: false,
        reportId: 0,
      },
      rowSelected: null as (IntelReport | null)
    }
  },
  computed: {
    ...mapState(['userProfile']),
    ...mapGetters(['isClient']),
    reportPrecedencesState(): { values: ReportPrecedence[], status: DataLoadStatus } {
      return this.$store.state.reportPrecedence;
    },
    precedences(): ReportPrecedence[] {
      return this.reportPrecedencesState.values
    },
    loading(): boolean {
      return this.reportPrecedencesState.status === DataLoadStatus.LOADING;
    },
    reportStatusOpts(): { key: ReportStatus, value: string }[] {
      return reportStatusOptions
    },
    headers(): Header[] {
      return [
        {text: '', filterable: false, sortable: false, value: 'actions', width: '1px'},
        {text: 'Num', value: 'displayNum', class: 'text-no-wrap', sortable: false},
        {text: 'Title', value: 'title', class: 'text-no-wrap report-title'},
        {text: 'Client', value: 'clientDisplayName', class: 'text-no-wrap'},
        {text: 'Precedence', sortable: false, value: 'precedence'},
        {text: 'Type', value: 'reportType', class: 'text-no-wrap'},
        {text: 'Status', value: 'reportStatus', class: 'text-no-wrap'},
        {text: 'Assignment', value: 'assignments', sortable: false, filterable: false, class: 'text-no-wrap'},
        {text: 'Released', filterable: false, value: 'releasedAt', class: 'text-no-wrap'},
        {text: 'Posted', filterable: false, value: 'createdAt', class: 'text-no-wrap'},
      ].filter(this.includeHeader);
    }
  },
  methods: {
    includeHeader(h: Header): boolean {
      const optional = ['createdAt', 'actions', 'clientDisplayName'];
      if (optional.includes(h.value)) {
        return !this.isClient
      }
      return true;
    },
    ...AuthHelper,
    getReportStatus(reportStatus: string | undefined) {
      if (reportStatusOptions) {
        return reportStatusOptions.find(status => status.key === reportStatus)?.value
      }
    },
    formatDate(dateStr: string) {
      return formatIsoDateToMmDdYyyy(dateStr);
    },
    async updateReportStatus(report: IntelReport) {
      try {
        this.savingReport = {saving: true, reportId: report.id};
        const updatedReport: IntelReport = await reportService.updateReportStatus(report.id, report.reportStatus);
        this.$emit('reportUpdated', updatedReport);
        this.showError = false;
        this.successMessage = 'Intel Report Status successfully updated';
        this.showSuccessMessage = true;
      } catch (e) {
        this.error = 'Failed to update the report status. Reason: ' + e.error;
        console.error('failed to update the report status', e);
        this.showError = true;
        this.showSuccessMessage = false;
      } finally {
        this.savingReport = {saving: false, reportId: 0};
      }
    },
    async deleteReport() {
      if (!this.deleteReportItem?.id) {
        return;
      }
      try {
        this.savingReport = {saving: true, reportId: this.deleteReportItem.id};
        await reportService.deleteReport(this.deleteReportItem.id);
        this.$emit('reportDeleted', this.deleteReportItem.id);
        this.showError = false;
        this.successMessage = 'Intel Report successfully deleted';
        this.showSuccessMessage = true;
      } catch (e) {
        this.error = 'Failed to delete the report. Reason: ' + e.error;
        console.error('failed to delete the report', e);
        this.showError = true;
        this.showSuccessMessage = false;
      } finally {
        this.showDeleteConfirmation = false
        this.savingReport = {saving: false, reportId: 0};
      }
    },
    handleMenuClick(val: unknown, item: IntelReport) {
      if (val) {
        this.rowSelected = item;
      } else if (item === this.rowSelected) {
        this.rowSelected = null;
      }
    },
    disableReportStatus(report: IntelReport, statusMenuItem: string) {
      if (report.reportStatus === statusMenuItem
          || (statusMenuItem === ReportStatus.PENDING_REVIEW && !this.canCreateIntelReport(this.userProfile))
          || (report.reportStatus === ReportStatus.PENDING_REVIEW && statusMenuItem !== ReportStatus.NEW)
          || ((report.reportStatus !== ReportStatus.NEW && report.reportStatus !== ReportStatus.PENDING_REVIEW)
              && (statusMenuItem === ReportStatus.PENDING_REVIEW || statusMenuItem === ReportStatus.NEW))
          || (report.reportType === ReportType.BROADCAST
              && (statusMenuItem !== ReportStatus.PENDING_REVIEW && statusMenuItem !== ReportStatus.NEW))) {
        return true;
      } else {
        return false;
      }
    },
    showStatusUpdateMenu(report: IntelReport) {
      if ((report.reportStatus === ReportStatus.PENDING_REVIEW && this.canReleaseIntelReport(this.userProfile))
          || (report.reportStatus !== ReportStatus.PENDING_REVIEW && report.reportType !== ReportType.BROADCAST)
          || (report.reportType === ReportType.BROADCAST && this.canReleaseIntelReport(this.userProfile))) {
        return true;
      } else {
        return false;
      }
    },
    editItem(item: IntelReport) {
      this.editReport = Object.assign({}, item);
      this.showNewReportForm = true
    },
    deleteItem(item: IntelReport) {
      this.deleteReportItem = item;
      this.showDeleteConfirmation = true
    },
    reportUpated(updatedReport: IntelReport, uploadSuccess: boolean) {
      this.showNewReportForm = false
      this.$emit('reportUpdated', updatedReport, uploadSuccess);
    },
    findPrecedence(precedenceId: number) {
      const precedence: ReportPrecedence | undefined = this.precedences.find(element => element.id === precedenceId)
      return precedence?.name
    },
    getReportTypeText(reportTypeValue: string) {
      return reportTypeOptions.find(opt => opt.value === reportTypeValue)?.text
    },
    reportNumber(report: IntelReport): string {
      if (!report.displayNum) {
        return '';
      } else if (report.reportType === ReportType.BROADCAST) {
        return `BC-${report.displayNum}`;
      }
      return `${report.clientDisplayNum ?? ''}-${report.displayNum}`;
    },
    isBroadcast(report: IntelReport): boolean {
      return report.reportType === ReportType.BROADCAST;
    },
    handleAssignmentSave(report: IntelReport, assignments: UserProfile[]) {
      report.assignments = assignments;
      this.successMessage = 'Assignment updated successfully.';
      this.showSuccessMessage = true;
    },
    handleEditingAssignment(report: IntelReport, isEditing: boolean) {
      if (isEditing) {
        this.rowSelected = report;
      } else if (report === this.rowSelected) {
        this.rowSelected = null;
      }
    }
  },
  mounted() {
    if (this.reportPrecedencesState.status === DataLoadStatus.NOT_LOADED) {
      this.$store.dispatch('loadReportPrecedences');
    }
  },
})

</script>

<style>
  .selected {
    background: #f8f8e3 !important;
  }
  .report-table td {
    padding: 10px 10px !important;
  }
  .report-title {
    min-width: 150px;
  }
</style>

<style scoped>
  .status-link {
    text-decoration: none;
  }
  .enum-val {
    font-size: .9em;
  }
</style>
