<template>
  <div class="mt-5">
    <h4 class="text-h4 mb-2">Admin console</h4>
    <v-tabs background-color="grey lighten-4">
      <v-tab v-if="canAdminClients(userProfile)" to="/admin/clients">
        <v-icon left>mdi-domain</v-icon>
        Clients
      </v-tab>
      <v-tab v-if="canAdminUsers(userProfile)" to="/admin/users">
        <v-icon left>mdi-account-multiple</v-icon>
        Users
      </v-tab>
      <v-tab v-if="canAdminUsers(userProfile)" to="/admin/invites">
        <v-icon left>mdi-account-multiple</v-icon>
        Invites
      </v-tab>
    </v-tabs>
    <div class="mt-5">
      <router-view/>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import AuthHelper from "@/services/AuthHelper";
import {mapState} from "vuex";

export default Vue.extend({
  name: "Admin",
  components: {},
  computed: {
    ...mapState(['userProfile']),
  },
  methods: {
    ...AuthHelper
  }
});
</script>

<style scoped>

</style>
