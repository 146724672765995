export default {
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: process.env.VUE_APP_COGNITO_REGION,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.VUE_APP_USER_POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,
    }
}
