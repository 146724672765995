import Service from "@/services/Service";
import {Client, ClientForSave} from "@/model/Client";
import UserProfile from "@/model/UserProfile";


interface DeleteClientResult {
    clientUuid: string,
    reportFilesDeletionFailed: boolean,
    sftFilesDeletionFailed: boolean,
    userPoolDeletionFailures: UserProfile[];
}

class ClientService extends Service {
    constructor() {
        super();
    }

    public async apiGetAllClients(): Promise<Array<Client>> {
        return (await this.axiosInstance.get<Client[]>('/client')).data;
    }

    public async apiPostClient(client: ClientForSave): Promise<Client> {
        return (await this.axiosInstance.post<Client>('/client', client)).data;
    }

    public async apiPutClient(client: ClientForSave): Promise<Client> {
        return (await this.axiosInstance.put<Client>('/client', client)).data;
    }

    public async getClientWithChildren(clientId: number): Promise<Client[]> {
        return (await this.axiosInstance.get<Client[]>(`/client/${clientId}/children`)).data;
    }

    public async deleteClient(clientId: number): Promise<DeleteClientResult> {
        return (await this.axiosInstance.delete<DeleteClientResult>(`/client/${clientId}`)).data;
    }
}

const clientService = new ClientService();
export default clientService;
