enum Privilege {
    ADMIN_USERS = 'admin-users',
    ADMIN_CLIENTS = 'admin-clients',
    CREATE_INTEL_REPORTS = 'create-intel-reports',
    RELEASE_INTEL_REPORTS = 'release-intel-reports',
    SFT_FROM_CLIENT = 'sft-from-client',
    SFT_TO_CLIENT = 'sft-to-client',
}

export default Privilege
