import { render, staticRenderFns } from "./UserGroupsSelect.vue?vue&type=template&id=20407d50&scoped=true"
import script from "./UserGroupsSelect.vue?vue&type=script&lang=ts"
export * from "./UserGroupsSelect.vue?vue&type=script&lang=ts"
import style0 from "./UserGroupsSelect.vue?vue&type=style&index=0&id=20407d50&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20407d50",
  null
  
)

export default component.exports