<template>
  <div class="new-report-form">
    <v-card>
      <v-progress-linear v-model="saveProgress" color="accent" v-if="saving"/>
      <v-alert v-if="error" type="error" rounded="0" dismissible data-test="error-alert">{{ error }}</v-alert>
      <v-card-title>
        <span class="text-h5 mb-3">{{ getTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="save" ref="form" v-model="valid" lazy-validation>
          <v-text-field
              v-model.trim="title"
              label="Title"
              dense
              outlined
              required
              :rules="titleRules"
              data-test="title-input"
          ></v-text-field>
          <v-select
              data-test="report-type-select"
              v-model="reportType"
              :items="reportTypeOptions"
              label="Report type"
              dense
              outlined
              :rules="reportTypeRules"
              :disabled="isReleasedBroadcast"
          ></v-select>
          <v-alert v-if="isBroadcast" dense type="info">The report will be sent to all clients</v-alert>
          <template v-if="!isBroadcast">
            <ClientSelect v-model="clientId" dense label="Client" :rules="clientRules" :disabled="isReleased"/>
          </template>
          <UserSelect :value="author ? author.cognitoId : null" emit-full-object @input="handleAuthorChange" :roles="roles" dense label="Report Author" data-test="author-select"/>
          <ReportPrecedenceSelect v-model="precedenceId" dense :rules="precedenceRules"/>

          <v-file-input v-model="file" :rules="fileRules" accept=".pdf, .pptx" ref="editReportRef" class="hide"
                        @change="fileSelected()" data-test="file-input"/>
          <div>
            <v-text-field v-model="filename" dense readonly outlined append-icon="mdi-pencil"
                          :rules="filenameRules"
                          @click:append="editFileToggle = true; $refs.editReportRef.$refs.input.click()"
                          @click="editFileToggle = true; $refs.editReportRef.$refs.input.click()"
                          label="Report (.pdf or .pptx)" data-test="file-input-readonly-text">
            </v-text-field>
          </div>

          <v-card-actions class="d-flex justify-end mt-5">
            <v-btn large @click="cancel" type="button" :disabled="saving" data-test="cancel-btn">Cancel</v-btn>
            <v-btn large color="primary" type="submit" :loading="saving">{{ getSaveButtonText }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Vue, {PropType} from "vue";
import FormElement from "@/model/FormElement";
import ClientSelect from "@/components/ClientSelect.vue";
import ReportType, {reportTypeOptions} from "@/model/ReportType";
import ReportPrecedenceSelect from "@/components/ReportPrecedenceSelect.vue";
import UserSelect from "@/components/UserSelect.vue";
import ReportStatus from "@/model/ReportStatus";
import reportService from "@/services/ReportService";
import IntelReport, {IntelReportForCreate} from "@/model/IntelReport";
import AuditInfo from "@/model/AuditInfo";
import Role from "@/model/Role";
import UserProfile from "@/model/UserProfile";
import { AxiosProgressEvent } from "axios";

export default Vue.extend({
  name: "NewReportForm",
  components: {ReportPrecedenceSelect, ClientSelect, UserSelect},
  props: {
    editReport: Object as PropType<IntelReport>,
  },
  data() {
    return {
      error: '',
      saving: false,
      valid: true,
      saveProgress: 0,
      editFileToggle: false,
      roles: [Role.ADMIN, Role.INTERNAL_USER],
      titleRules: [(v: string) => !!v || 'Title is required'],
      clientRules: [(v: string) => !!v || 'Client is required'],
      reportTypeRules: [(v: string) => !!v || 'Report type is required'],
      precedenceRules: [(v: string) => !!v || 'Precedence is required'],
      fileRules: [(v: string) => (!!this.editReport || !!v) || '.pdf or .pptx is required'],
      filenameRules: [(v: string) => !!v || '.pdf or .pptx is required'],

      // form
      title: this.editReport?.title || '',
      clientId: this.editReport?.clientId || null as (number | null),
      reportType: ((this.editReport?.reportType) || null) as (ReportType | null),
      precedenceId: this.editReport?.precedenceId || null as (number | null),
      author: this.editReport ? this.editReport.author : {
        cognitoId: this.$store.state.userProfile.cognitoId,
        firstName: this.$store.state.userProfile.firstName,
        lastName: this.$store.state.userProfile.lastName,
        email: this.$store.state.userProfile.email
      } as AuditInfo,
      file: null as (null | File),
      filename: this.editReport?.filename || null as (null | string),
    }
  },
  computed: {
    formElement(): FormElement {
      return this.$refs.form as unknown as FormElement;
    },
    reportTypeOptions(): { text: string, value: ReportType }[] {
      return reportTypeOptions.map(o => ({
        ...o,
        disabled: o.value === ReportType.BROADCAST && this.isReleased && !this.isReleasedBroadcast
      }));
    },
    isBroadcast(): boolean {
      return this.reportType === ReportType.BROADCAST
    },
    getSaveButtonText(): string {
      return this.editReport ? 'Update' : 'Post';
    },
    getTitle(): string {
      return this.editReport ? 'Update INTEL report' : 'New INTEL report';
    },
    editFile(): boolean {
      return this.editFileToggle || !this.editReport;
    },
    isReleased(): boolean {
      return this.editReport && this.editReport.reportStatus !== ReportStatus.PENDING_REVIEW;
    },
    isReleasedBroadcast(): boolean {
      return this.isReleased && this.editReport.reportType === ReportType.BROADCAST;
    }
  },
  methods: {
    reset() {
      this.formElement.resetValidation();
      if (this.editReport) {
        this.title = this.editReport.title;
        this.author = this.editReport.author;
      } else {
        this.author = {
          cognitoId: this.$store.state.userProfile.cognitoId,
          firstName: this.$store.state.userProfile.firstName,
          lastName: this.$store.state.userProfile.lastName,
          email: this.$store.state.userProfile.email
        }
        this.title = '';
        this.filename = '';
      }
      this.clientId = null;
      this.reportType = null;
      this.precedenceId = null;
      this.file = null;
      this.saveProgress = 0;
    },
    handleAuthorChange(authorProfile: UserProfile) {
      this.author = {
        cognitoId: authorProfile.cognitoId ?? '',
        firstName: authorProfile.firstName ?? '',
        lastName: authorProfile.lastName ?? '',
        email: authorProfile.email
      }
    },
    validate() {
      return this.formElement.validate();
    },
    cancel() {
      this.$emit('cancel');
      this.reset();
    },
    reportForSave(report: IntelReport | null): IntelReportForCreate {
      const reportStatus = report ? report.reportStatus : ReportStatus.PENDING_REVIEW;
      const filename = this.file ? this.file!.name : '';
      return {
        title: this.title,
        reportType: this.reportType!,
        reportStatus: reportStatus,
        precedenceId: this.precedenceId!,
        clientId: this.reportType === ReportType.BROADCAST ? null : this.clientId!,
        author: this.author!,
        filename: filename
      }
    },
    async save() {
      if (!this.validate()) {
        return;
      }

      try {
        this.saving = true;

        let result;
        if (this.editReport) {
          result = await reportService.updateReport(this.editReport.id, this.reportForSave(this.editReport));
        } else {
          result = await reportService.createReport(this.reportForSave(null));
        }
        this.saveProgress = 10;
        let uploadSuccess = false;
        try {
          if (this.file) {
            await reportService.uploadReport(result.signedPutUrl, this.file!, this.uploadProgress);
          }
          uploadSuccess = true;
          await reportService.setUploadVerified(result.intelReport.id);
        } catch (e) {
          console.error('failed to upload and verify the report', e);
        }
        if (this.editReport) {
          this.$emit('updated', result.intelReport, uploadSuccess);
        } else {
          this.$emit('created', result.intelReport, uploadSuccess);
        }
        this.reset();
      } catch (e) {
        const persistType = this.editReport ? 'update' : 'create';
        this.error = `Failed to ${persistType} the report`;
        console.error(`failed to ${persistType} report`, e);
      } finally {
        this.saving = false;
      }
    },
    uploadProgress(progressEvent: AxiosProgressEvent) {
      const uploadPercent = progressEvent.total ? (progressEvent.loaded / progressEvent.total) : 0;
      this.saveProgress = 10 + (uploadPercent * 90);
    },
    fileNameDisplay() {
      this.editReport ? this.editReport.filename : this.file!.name
    },
    fileSelected() {
      if (this.file && this.file.name) {
        this.filename = this.file.name;
      }
    }
  }
});

</script>

<style scoped>
.hide {
  display: none
}
</style>
