<template>
  <div class="login-container">
    <Authenticator @signed-in="handleSignedIn"/>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import {mapState} from "vuex";
import Authenticator from "@/components/auth/Authenticator.vue";
import {noop} from "@/services/view-helpers";

export default Vue.extend({
  name: "Login",
  components: {Authenticator},
  computed: {
    ...mapState(['originalRoute', 'user']),
  },
  mounted() {
    if (this.user) {
      this.$router.push('/').catch(noop);
    }
  },
  methods: {
    async handleSignedIn(authData: unknown) {
      await this.$store.dispatch('loginUser', authData);
      await this.$router.push(this.originalRoute ?? '/');
      this.$store.commit('setOriginalRoute', null);
    }
  },
})
</script>

<style scoped>
  .login-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .login-container>* {
    flex-grow: 1;
  }
</style>

<style>
amplify-authenticator {
  display: block;
  margin-top: 30px;
  --container-height: 100%;
  --box-shadow: 1px 1px 4px 0 grey;
}
</style>
