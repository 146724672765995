import Privilege from "@/model/Privilege";
import UserProfile from "@/model/UserProfile";

function can(user:UserProfile, ...privileges: Privilege[]): boolean {
    if (privileges.length === 0) {
        return true;
    }

    for (const p of privileges) {
       if (user?.privileges?.includes(p) ?? false) {
           return true;
       }
    }
    return false;
}

export default {
    can,
    canAdminUsers: (user: UserProfile): boolean => can(user, Privilege.ADMIN_USERS),
    canAdminClients: (user: UserProfile): boolean => can(user, Privilege.ADMIN_CLIENTS),
    canAdminUsersOrClients: (user: UserProfile): boolean => can(user, Privilege.ADMIN_USERS, Privilege.ADMIN_CLIENTS),
    canCreateIntelReport: (user: UserProfile): boolean => can(user, Privilege.CREATE_INTEL_REPORTS),
    canReleaseIntelReport: (user: UserProfile): boolean => can(user, Privilege.RELEASE_INTEL_REPORTS),
    canSftToClient: (user: UserProfile): boolean => can(user, Privilege.SFT_TO_CLIENT),
    canSftFromClient: (user: UserProfile): boolean => can(user, Privilege.SFT_FROM_CLIENT),
}
