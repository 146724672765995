<template>
  <v-footer app inset absolute elevation="0" height="100px">
    <div class="content">
      <div>
        <v-img
            class="mt-3 mb-2"
            alt="logo"
            src="../assets/logo.png"
            width="100"
            contain
        ></v-img>
      </div>
      <div class="copy">Cybeta © Copyright {{year}}</div>
    </div>
  </v-footer>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Footer",
  computed: {
    version() {
      return process.env.VUE_APP_VERSION?.substring(0,8) ?? '--'
    },
    year() {
      return new Date().getFullYear();
    }
  },
})
</script>

<style scoped>
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    font-size: .9em;
  }

  .copy {
    font-size: .9em;
    color: grey;
  }
</style>
