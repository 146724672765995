<template>
  <v-card v-if="user && userProfile">
    <v-alert v-if="error" type="error" dismissible>{{error}}</v-alert>
    <v-card-title class="primary--text">
      <div class="title">
        <div>Profile Information</div>
        <v-btn v-if="!editing" color="primary" outlined small @click="editing=true" data-test="edit-profile-btn">Edit</v-btn>
      </div>
    </v-card-title>
    <v-divider/>
    <v-card-text class="mb-6">
      <v-form v-if="editing" ref="form" v-model="valid" lazy-validation @submit.prevent="save" data-test="edit-profile-form">
        <v-text-field dense outlined v-model="editedProfile.firstName" :rules="[requiredRule]" label="First name" name="firstName"/>
        <v-text-field dense outlined v-model="editedProfile.lastName" :rules="[requiredRule]" label="Last name" name="lastName"/>
        <v-text-field dense outlined :value="userProfile.clientDisplayName" label="Account" disabled/>
        <v-text-field dense outlined v-model="editedProfile.email" :rules="[requiredRule, emailRule]" label="Email" name="email"/>
        <p class="font-weight-light">Phone number</p>
        <PhoneField v-model="editedProfile.phoneObject" required/>
        <v-alert type="info" dense>The phone number is used for multi-factor authentication. You must enter a number where you can receive SMS messages.</v-alert>
        <v-btn type="submit" class="d-none">Hidden save</v-btn>
      </v-form>

      <template v-else>
        <div class="text-h6" data-test="name-title">{{name}}</div>
        <div class="attribute"><v-icon class="mr-4" color="accent" small>mdi-domain</v-icon><span>{{userProfile.clientDisplayName || '--'}}</span></div>
        <div class="attribute"><v-icon class="mr-4" color="accent" small>mdi-email</v-icon><span data-test="email-txt">{{email}}</span></div>
        <div class="attribute"><v-icon class="mr-4" color="accent" small>mdi-phone</v-icon><span data-test="phone-txt">{{userProfile.phoneNumber}}</span></div>
      </template>
    </v-card-text>
    <template v-if="editing">
      <v-divider/>
      <v-card-actions class="d-flex justify-end pa-4">
        <v-btn class="mr-4" @click="cancelEdits" data-test="cancel-prefs-btn">Cancel</v-btn>
        <v-btn color="primary" @click="save" :loading="saving" data-test="save-profile-btn">Save</v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script lang="ts">
import Vue, {PropType} from "vue";
import UserProfile, {UserProfileForEdit} from "../../model/UserProfile";
import User from "@/model/User";
import {requiredRule, emailRule} from "@/services/ValidationRules";
import {decomposePhone, Phone} from "@/services/view-helpers";
import PhoneField from "@/components/auth/PhoneField.vue";
import FormElement from "@/model/FormElement";
import userService from "@/services/UserService";

interface EditedProfile extends UserProfileForEdit {
  phoneObject: Phone
}

export default Vue.extend({
  name: "UserInfo",
  components: {PhoneField},
  props: {
    user: Object as PropType<User>,
    userProfile: Object as PropType<UserProfile>
  },
  data() {
    return {
      error: '',
      editing: false,
      editedProfile: null as (null|EditedProfile),
      saving: false,
      valid: true,
    }
  },
  computed: {
    name(): string {
      return `${this.userProfile.firstName ?? ''} ${this.userProfile.lastName ?? ''}`;
    },
    email(): string {
      return `${this.userProfile.email ?? ''}`
    },
    userProfileForEdit(): UserProfileForEdit {
      return {
        email: this.editedProfile?.email ?? '',
        firstName: this.editedProfile?.firstName ?? '',
        lastName: this.editedProfile?.lastName ?? '',
        phoneNumber: `${this.editedProfile?.phoneObject.countryCode ?? ''}${this.editedProfile?.phoneObject.number ?? ''}`,
      }
    }
  },
  mounted() {
    this.resetEditedProfile();
  },
  methods: {
    formElement(): FormElement {
      return this.$refs.form as unknown as FormElement;
    },
    requiredRule,
    emailRule,
    resetEditedProfile() {
      if (this.formElement()) {
        this.formElement().resetValidation();
      }
      this.editedProfile = {
        email: this.userProfile.email,
        firstName: this.userProfile.firstName,
        lastName: this.userProfile.lastName,
        phoneNumber: this.userProfile.phoneNumber,
        phoneObject: this.userProfile.phoneNumber ?
            decomposePhone(this.userProfile.phoneNumber):
            {countryCode: '+1', number: ''},
      }
    },
    validate () {
      return this.formElement().validate();
    },
    cancelEdits() {
      this.editing = false;
      this.resetEditedProfile();
    },
    async save() {
      if (!this.validate()) {
        return;
      }

      try {
        this.saving = true;
        this.error = '';
        await userService.updateCurrentUserProfile(this.userProfileForEdit);
        this.$store.commit('setUserProfileEmailNameAndNumber', this.userProfileForEdit);
        this.editing = false;
      } catch (e) {
        console.error('Failed to update profile information.', e);
        this.error = 'Failed to update profile information.';
      } finally {
        this.saving = false;
      }
    }
  }
});
</script>

<style scoped>
  .attribute {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  .attribute>span {
    font-size: 1.1em;
  }
  .title {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
</style>
