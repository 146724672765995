<template>
  <div class="profile">
    <UserInfo :user="user" :user-profile="userProfile"/>
    <NotificationPrefs :user-profile="userProfile"/>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import NotificationPrefs from "@/components/profile/NotificationPrefs.vue";
import {mapState} from "vuex";
import UserInfo from "@/components/profile/UserInfo.vue";

export default Vue.extend({
  name: "ProfileSettings",
  components: {UserInfo, NotificationPrefs},
  computed: {
    ...mapState(['user', 'userProfile']),
  }
})
</script>

<style scoped>
  .profile {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-row-gap: 30px;
    grid-column-gap: 30px;
  }
</style>
