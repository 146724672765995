<template>
    <v-snackbar :value="value" @input="$emit('input', $event)" outlined :color="color" content-class="font-weight-black" text top timeout="3000">
        <slot/>
        <template v-slot:action="{ attrs }">
            <v-btn :color="color" icon v-bind="attrs" @click="$emit('input', false)">
                <v-icon>mdi-close</v-icon>
            </v-btn>
      </template>
    </v-snackbar>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: 'SnackbarMessage',
  props: {
    value: Boolean,
    error: Boolean
  },
  computed: {
    color(): string {
      return this.error ? 'error' : 'success';
    }
  }
})
</script>

<style>

</style>
