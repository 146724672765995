<template>
  <Page>
    <div class="d-flex justify-center text-center">
      <div style="width: 300px">
        <div class="mb-3">
          <div class="text-h5">Test API connectivity</div>
          <div v-if="!user" class="overline secondary--text">Please sign in first.</div>
        </div>
        <div>
          <v-btn color="primary" large @click="lookupApiVersion" :disabled="!user">Ping</v-btn>
        </div>
        <v-divider class="my-8"/>
        <div class="mt-5">
          <div v-if="loadingApiVersion">pinging...</div>
          <div v-else>
            <v-alert v-if="!error && version" type="success" border="left" colored-border elevation="1" prominent>
              <div>Successfully connected!</div>
            </v-alert>
            <v-alert v-if="error" type="error" border="left" colored-border elevation="1">Failed to connect</v-alert>
            <div class="build-no">API build: {{version || '--'}}</div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script lang="ts">
  import Vue from 'vue'
  import versionService from "@/services/VersionService";
  import {mapState} from "vuex";
  import Page from "@/components/Page.vue";

  export default Vue.extend({
    name: 'Health',
    components: {Page},
    data() {
      return {
        loadingApiVersion: false,
        error: '',
        apiVersion: '',
      }
    },
    computed: {
      ...mapState(['user']),
      version(): string {
        return this.apiVersion?.substring(0,8);
      }
    },
    watch: {
      user() {
        this.apiVersion = '';
        this.error = '';
      }
    },
    methods: {
      async lookupApiVersion() {
        try {
          this.loadingApiVersion = true;
          this.apiVersion = await versionService.apiVersion();
          this.error = '';
        } catch (e) {
          console.error('failed to lookup the api version', e);
          this.error = e.message;
        } finally {
          this.loadingApiVersion = false;
        }
      }
    }
  })
</script>

<style scoped>
  .build-no {
    position: relative;
    top: -10px;
    font-size: .9em;
    color: gray;
  }
</style>
