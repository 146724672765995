<template>
  <div class="user-select">
    <v-autocomplete
        data-test="user-select"
        :value="value"
        @input="handleAutocompleteInput"
        :items="items"
        outlined
        :label="label"
        :search-input.sync="searchText"
        item-color="accent"
        auto-select-first
        :loading="loading"
        :dense="dense"
        :rules="rules"
        :placeholder="placeholder"
        :disabled="disabled"
        clearable
        :multiple="multiple"
        :chips="multiple"
        :small-chips="dense && multiple"
        :deletable-chips="multiple"
    ></v-autocomplete>
    <SnackbarMessage error v-model="error">Failed to load users</SnackbarMessage>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import userService from "@/services/UserService";
import UserProfile from "@/model/UserProfile";
import SnackbarMessage from "@/components/SnackbarMessage.vue";
import Role from "@/model/Role";

export default Vue.extend({
  name: "UserSelect",
  components: {SnackbarMessage},
  props: {
    value: [String, Array],
    label: String,
    dense: {
      type: Boolean,
      default: false,
    },
    rules: Array,
    placeholder: String,
    disabled: Boolean,
    roles: Array,
    emitFullObject: Boolean,
    clientIdFilter: Number,
    multiple: Boolean,
  },
  data() {
    return {
      loading: true,
      searchText: '',
      userList: [] as UserProfile[],
      error: false,
    }
  },
  computed: {
    items(): { text: string, value: string}[] {
      const nonNullUserList = this.userList.filter(u => !!u.firstName && !!u.lastName);
      return nonNullUserList.map(a => ({
        text: `${a?.firstName} ${a?.lastName}`,
        value: a.cognitoId ?? ''
      }))
    },
  },
  created() {
    this.loadUserList();
  },
  methods: {
    async loadUserList() {
      this.loading = true;
      this.error = false;
      try {
        this.userList = await userService.getUserProfiles(this.roles as Role[], this.clientIdFilter);
      } catch (e) {
        console.error('failed to load users:', e);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    handleAutocompleteInput(val: (string[]|string)) {
      if (this.emitFullObject) {
        if (this.multiple && Array.isArray(val)) {
          this.$emit('input', this.userList.filter(u => val.includes(u.cognitoId ?? '')));
        } else {
          this.$emit('input', this.userList.find(u => u.cognitoId === val));
        }
      } else {
        this.$emit('input', val);
      }
    }
  }
})
</script>

<style scoped>

</style>
