<template>
  <v-form ref="form" @submit.prevent="submit" v-model="valid" lazy-validation>
    <v-alert type="error" v-if="error" dismissible>{{error}}</v-alert>
    <h3>Setup your profile</h3>
    <p class="mt-2 font-weight-light">Choose a new password.</p>
    <v-text-field dense outlined v-model="password" :rules="[requiredRule, passwordRule]" label="New password" type="password" validate-on-blur/>
    <v-text-field dense outlined v-model="passwordConfirmation" :rules="[requiredRule, passwordConfirmRule]" label="Retype password" type="password" validate-on-blur/>
    <template v-if="requiresAttributes">
      <p class="my-5 font-weight-light">Fill in the rest of your profile.</p>
      <v-text-field v-if="requiresFirstName" dense outlined v-model="firstName" :rules="[requiredRule]" label="First name"/>
      <v-text-field v-if="requiresLastName" dense outlined v-model="lastName" :rules="[requiredRule]" label="Last name"/>

      <template v-if="requiresPhoneNumber">
        <p class="font-weight-light">Phone number</p>
        <PhoneField v-model="phoneNumber" required/>
        <v-alert type="info" dense>The phone number is used for multi-factor authentication. You must enter a number where you can receive SMS messages.</v-alert>
      </template>
    </template>

    <div class="mt-5">
      <v-btn color="primary" type="submit" :loading="submitting">Update profile</v-btn>
    </div>
  </v-form>
</template>

<script lang="ts">
import Vue, {PropType} from "vue";
import PhoneField from "@/components/auth/PhoneField.vue";
import {passwordRule, requiredRule} from "@/services/ValidationRules";
import FormElement from "@/model/FormElement";
import {Auth} from "aws-amplify";
import {AuthResponse} from "@/model/AuthTypes";

export default Vue.extend({
  name: "CompleteProfileForm",
  components: {PhoneField},
  props: {
    currentAuthRes: Object as PropType<AuthResponse>
  },
  data() {
    return {
      error: '',
      valid: true,
      submitting: false,

      password: '',
      passwordConfirmation: '',
      firstName: '',
      lastName: '',
      phoneNumber: {
        countryCode: '+1',
        number: '',
      }
    }
  },
  computed: {
    formElement(): FormElement {
      return this.$refs.form as unknown as FormElement;
    },
    fullPhone(): string {
      return `${this.phoneNumber.countryCode}${this.phoneNumber.number}`;
    },
    requiresAttributes(): boolean {
      return (this.currentAuthRes.challengeParam?.requiredAttributes?.length ?? 0) > 0;
    },
    requiresFirstName(): boolean {
      return this.currentAuthRes.challengeParam?.requiredAttributes?.includes('given_name') ?? false;
    },
    requiresLastName(): boolean {
      return this.currentAuthRes.challengeParam?.requiredAttributes?.includes('family_name') ?? false;
    },
    requiresPhoneNumber(): boolean {
      return this.currentAuthRes.challengeParam?.requiredAttributes?.includes('phone_number') ?? false;
    }
  },
  methods: {
    requiredRule,
    passwordRule,
    passwordConfirmRule(v: string) {
      if (v !== this.password) {
        return 'Does not match';
      }
      return true;
    },
    validate () {
      return this.formElement.validate();
    },
    async submit() {
      this.error = '';

      if (!this.validate()) {
        return;
      }

      try {
        this.submitting = true;
        const attributes: {given_name?: string, family_name?: string, phone_number?: string} = {};
        if (this.requiresFirstName) {
          attributes.given_name = this.firstName;
        }
        if (this.requiresLastName) {
          attributes.family_name = this.lastName;
        }
        if (this.requiresPhoneNumber) {
          attributes.phone_number = this.fullPhone;
        }

        const result = await Auth.completeNewPassword(this.currentAuthRes, this.password, attributes);
        this.$emit('auth-response', result);
      } catch (e) {
        console.error('failure completing profile', e);
        if (e.message.match(/session is expired/i)) {
          this.$emit('session-expired');
        } else {
          this.error = e.message;
        }
      } finally {
        this.submitting = false;
      }
    }
  }
});
</script>

<style scoped>
</style>
