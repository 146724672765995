import Service from "@/services/Service";

interface ApiVersion {
    version: string
}

class VersionService extends Service {
    constructor() {
        super();
    }

    public async apiVersion(): Promise<string> {
        return (await this.axiosInstance.get<ApiVersion>('/version')).data.version;
    }
}

const versionService = new VersionService();
export default versionService;
