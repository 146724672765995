<template>
  <div class="client-select">
    <v-autocomplete
        data-test="client-select"
        :value="value"
        @input="$emit('input', $event)"
        :items="items"
        outlined
        :label="label === undefined ? defaultLabel : label"
        :multiple="multiple"
        :chips="multiple"
        :clearable="clearable"
        deletable-chips
        :search-input.sync="searchText"
        @keyup.enter="clearSearch"
        item-color="accent"
        auto-select-first
        :loading="loading"
        :dense="dense"
        :rules="rules"
        :hint="hint"
        :persistent-hint="persistentHint"
        :placeholder="placeholder"
        :small-chips="dense && multiple"
        :disabled="disabled"
    ></v-autocomplete>
    <SnackbarMessage error v-model="error">Failed to load client list</SnackbarMessage>
  </div>
</template>

<script lang="ts">
import Vue, {PropType} from "vue";
import clientService from "@/services/ClientService";
import {Client} from "@/model/Client";
import SnackbarMessage from "@/components/SnackbarMessage.vue";

export default Vue.extend({
  name: "ClientSelect",
  components: {SnackbarMessage},
  props: {
    value: [Number, Array],
    multiple: Boolean,
    label: String,
    dense: {
      type: Boolean,
      default: false,
    },
    disableChildrenOf: Number,
    disableFunc: Function as PropType<(c: Client)=>boolean>,
    onlyChildrenOf: Number,
    rules: Array,
    hint: String,
    persistentHint: Boolean,
    clearable: Boolean,
    placeholder: String,
    disabled: Boolean,
  },
  data() {
    return {
      loading: true,
      searchText: '',
      clients: [] as Client[],
      error: false,
      disabledClientIds: new Array<number>(),
    }
  },
  computed: {
    defaultLabel(): string {
      return this.multiple ? 'Clients': 'Client';
    },
    items(): {text: string, value: number}[] {
      return this.clients.map(c => ({
        text: c.name,
        value: c.id ?? -1,
        disabled: c.id && (this.disabledClientIds.includes(c.id) || (this.disableFunc && this.disableFunc(c))),
      }))
    }
  },
  created() {
    this.loadClients();
  },
  watch: {
    disableChildrenOf() {
      this.loadChildren();
    },
    onlyChildrenOf() {
      this.loadClients();
    }
  },
  methods: {
    clearSearch() {
      if (this.multiple) {
        this.searchText = '';
      }
    },
    async loadClients() {
      this.loading = true;
      this.error = false;
      try {
        let clientsPromise: Promise<Client[]>;
        if (this.onlyChildrenOf) {
          clientsPromise = clientService.getClientWithChildren(this.onlyChildrenOf).then(clients => clients.filter(c => c.id !== this.onlyChildrenOf));
        } else {
          clientsPromise = clientService.apiGetAllClients();
        }
        await this.loadChildren();
        this.clients = (await clientsPromise).sort((c1, c2) => c1.name.toUpperCase().localeCompare(c2.name.toUpperCase()));
      } catch (e) {
        console.error('failed to lookup clients', e);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    async loadChildren() {
      if (this.disableChildrenOf) {
        const children = await clientService.getClientWithChildren(this.disableChildrenOf);
        this.disabledClientIds = children.map(c => c.id as number);
      } else {
        this.disabledClientIds = [];
      }
    }
  }
})
</script>

<style scoped>

</style>
