<template>
  <Page/>
</template>

<script lang="ts">
  import Vue from 'vue'
  import {mapState} from "vuex";
  import AuthHelper from "@/services/AuthHelper";
  import Page from "@/components/Page.vue";
  import {noop} from "@/services/view-helpers";

  export default Vue.extend({
    name: 'Redirect',
    components: {Page},
    data() {
      return {
      }
    },
    computed: {
      ...mapState(['userProfile']),
    },
    methods: {
      redirect() {
        if (this.userProfile && AuthHelper.canAdminUsers(this.userProfile)) {
          this.$router.replace('/admin/users').catch(noop);
        } else {
          this.$router.replace('/reports').catch(noop);
        }
      }
    },
    mounted() {
      this.redirect();
    }
  })
</script>

<style scoped>

</style>
