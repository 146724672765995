<template>
  <div class="new-user-form">
    <v-card>
      <v-progress-linear v-if="loadingUserProfileToEdit" indeterminate/>
      <v-alert v-if="!isEdit" type="info" rounded="0" class="mb-0">New users will receive an email with a temporary password and instructions for logging in and completing their profile.</v-alert>
      <v-alert v-if="error" type="error" rounded="0" dismissible data-test="error-alert">{{error}}</v-alert>
      <v-card-title>
        <span class="text-h5">{{title}}</span>
      </v-card-title>
      <v-card-text>
        <template v-if="loadingError">
          <v-alert type="error">Sorry, there was an error loading the user information to edit.</v-alert>
          <v-card-actions class="d-flex justify-end mt-5">
            <v-btn large @click="cancel" type="button" :disabled="saving">Ok</v-btn>
          </v-card-actions>
        </template>
        <template v-else>
          <v-form @submit.prevent="save" ref="form" v-model="valid">
            <v-text-field
                dense
                v-model="email"
                label="Email"
                required
                outlined
                :rules="[requiredRule, emailRule]"
                name="email"
                validate-on-blur
                :disabled="loadingUserProfileToEdit"
            />

            <RoleSelect :disabled="loadingUserProfileToEdit" dense v-model="role" :rules="roleRules"/>

            <div class="mb-8" data-test="access-groups-div">
              <div class="overline">Access groups</div>
              <v-alert v-if="isAdminRole" type="info" outlined>Admin users have full access to the system.</v-alert>
              <div v-show="!isAdminRole" class="access-groups">
                <div v-if="!role" class="font-italic">Choose a role to see available access groups</div>
                <UserGroupsSelect v-show="!isAdminRole" ref="userGroupSelect" v-model="userGroups" :role="role" :disabled="isAdminRole"/>
              </div>
            </div>

            <ClientSelect :disabled="loadingUserProfileToEdit" v-model="clientId" label="Primary account" :rules="clientIdRules" dense/>
            <div style="width: 300px;"><v-switch :disabled="loadingUserProfileToEdit" class="mt-0" dense v-model="allChildClientAccess" label="Allow access to all child accounts" /></div>
            <template v-if="!allChildClientAccess">
              <ClientSelect :disabled="loadingUserProfileToEdit" v-model="childClientAccess" multiple label="Select child account access" persistent-hint :only-children-of="clientId" clearable dense/>
            </template>

            <template v-if="isEdit">
              <v-text-field :disabled="loadingUserProfileToEdit" dense outlined v-model="firstName" :rules="[firstNameRule]" label="First name" name="firstName"/>
              <v-text-field :disabled="loadingUserProfileToEdit" dense outlined v-model="lastName" :rules="[lastNameRule]" label="Last name" name="lastName"/>

              <p class="font-weight-light">Phone number</p>
              <PhoneField v-model="phoneNumber" :required="userProfileToEditHasPhone" :disabled="loadingUserProfileToEdit"/>
              <v-alert type="info" dense>The phone number is used for multi-factor authentication. You must enter a number where the user can receive SMS messages.</v-alert>
            </template>
            <v-card-actions class="d-flex justify-end mt-5">
              <v-btn large @click="cancel" type="button" :disabled="saving">Cancel</v-btn>
              <v-btn large color="primary" type="submit" :loading="saving">Save</v-btn>
            </v-card-actions>
          </v-form>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import userService from "@/services/UserService";
import ClientSelect from "@/components/ClientSelect.vue";
import RoleSelect from "@/components/RoleSelect.vue";
import FormElement from "@/model/FormElement";
import UserProfile from "@/model/UserProfile";
import {emailRule, requiredRule} from "@/services/ValidationRules";
import {decomposePhone} from "@/services/view-helpers";
import PhoneField from "@/components/auth/PhoneField.vue";
import Role from "@/model/Role";
import UserGroupsSelect from "@/components/UserGroupsSelect.vue";

export default Vue.extend({
  name: "UserForm",
  components: {UserGroupsSelect, ClientSelect, RoleSelect, PhoneField},
  props: {
    userToEditId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      email: '',
      role: null as (null|Role),
      clientId: null as (number|null),
      allChildClientAccess: false,
      childClientAccess: new Array<number>(),
      firstName: '',
      lastName: '',
      phoneNumber: {
        countryCode: '+1',
        number: ''
      },
      userGroups: new Array<number>(),

      loadingUserProfileToEdit: false,
      loadingError: false,
      userProfileForEdit: null as (null|UserProfile),

      saving: false,
      error: '',
      valid: true,
      clientIdRules: [
        (v:string) => !!v || 'primary account is required',
      ],
      roleRules: [
        (v:string) => !!v || 'role is required',
      ],
    }
  },
  computed: {
    formElement(): FormElement {
      return this.$refs.form as unknown as FormElement;
    },
    isEdit(): boolean {
      return !!this.userToEditId;
    },
    title(): string {
      return this.isEdit ? 'Update user' : 'Provision a new user';
    },
    userProfileToEditHasPhone(): boolean {
      return !!this.userProfileForEdit?.phoneNumber;
    },
    isAdminRole(): boolean {
      return this.role === Role.ADMIN;
    },
  },
  mounted() {
    if (this.userToEditId) {
      this.loadUserProfileForEdit();
    } else {
      this.reset();
    }
  },
  watch: {
    userToEditId(id) {
      if (id) {
        this.loadUserProfileForEdit();
      } else {
        this.userProfileForEdit = null;
        this.reset();
      }
    },
    role() {
      if (!this.userProfileForEdit || this.userProfileForEdit.role !== this.role) {
        setImmediate(() => {
          (this.$refs.userGroupSelect as unknown as {selectDefaultForRole: ()=>void}).selectDefaultForRole();
        });
      }
    }
  },
  methods: {
    requiredRule,
    emailRule,
    firstNameRule(v: string) {
      // required if previously entered
      if (this.userProfileForEdit?.firstName) {
        return requiredRule(v);
      }
      return true;
    },
    lastNameRule(v: string) {
      // required if previously entered
      if (this.userProfileForEdit?.lastName) {
        return requiredRule(v);
      }
      return true;
    },
    async loadUserProfileForEdit() {
      try {
        this.loadingUserProfileToEdit = true;
        this.userProfileForEdit = await userService.getFullUserProfile(this.userToEditId);
        this.reset();
      } catch (e) {
        console.error('failed to lookup the user', e);
        this.loadingError = true;
      } finally {
        this.loadingUserProfileToEdit = false;
      }
    },
    reset() {
      if (this.formElement) {
        this.formElement.resetValidation();
      }

      this.email = this.userProfileForEdit?.email ?? '';
      this.role = this.userProfileForEdit?.role ?? null;
      this.childClientAccess = this.userProfileForEdit?.childClientAccess ?? [];
      this.allChildClientAccess = this.userProfileForEdit?.allChildClientAccess || false;
      this.clientId = this.userProfileForEdit?.clientId ?? null;

      this.firstName = this.userProfileForEdit?.firstName ?? '';
      this.lastName = this.userProfileForEdit?.lastName ?? '';
      this.phoneNumber = this.userProfileForEdit?.phoneNumber ? decomposePhone(this.userProfileForEdit.phoneNumber) : {countryCode: '+1', number: ''};
      this.userGroups = this.userProfileForEdit?.groupIds ?? [];

      this.saving = false;
      this.error = '';
    },
    cancel() {
      this.reset();
      this.$emit('cancel');
    },
    validate () {
      return this.formElement.validate();
    },
    async save() {
      if (!this.validate()) {
        return;
      }

      this.error = '';
      this.saving = true;
      try {
        const profile: Partial<UserProfile> = {
          email: this.email,
          clientId: this.clientId,
          // Suppressing because this.validate() ensures that role is populated
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          role: this.role!,
          allChildClientAccess: this.allChildClientAccess,
          groupIds: this.isAdminRole ? undefined : this.userGroups
        };
        if (!this.allChildClientAccess) {
          profile.childClientAccess = this.childClientAccess;
        }
        if (this.isEdit) {
          profile.firstName = this.firstName;
          profile.lastName = this.lastName;
          if (this.phoneNumber.countryCode && this.phoneNumber.number) {
            profile.phoneNumber = `${this.phoneNumber.countryCode}${this.phoneNumber.number}`;
          } else {
            profile.phoneNumber = '';
          }
        }

        let saved: UserProfile;
        if (this.isEdit) {
          saved = await userService.updateUserProfile(this.userToEditId, profile);
        } else {
          saved = await userService.createUserProfile(profile);
        }
        this.$emit('saved', saved);
        this.reset();
      } catch (e) {
        console.error('failed to save the user', e);
        this.error = e.error === 'duplicate-email' ?
            'There is already a user with that email address.' :
            'Failed to save the user.';
      } finally {
        this.saving = false;
      }
    },
  }
});
</script>

<style scoped>
  .access-groups {
    border: 1px solid gray;
    padding: 10px;
    border-radius: 5px;
  }
</style>
