<template>
  <div class="d-flex phone-field">
    <v-select :disabled="disabled" dense outlined class="flex-grow-0 mr-2" style="width: 100px;" :value="value.countryCode" @input="handleCountryCodeInput" :items="countryCodes" label="Country" :rules="[requiredRule]"></v-select>
    <v-text-field :disabled="disabled" dense outlined :value="value.number" @input="handleNumberInput" :rules="rules" label="Number" validate-on-blur name="number"/>
  </div>
</template>

<script lang="ts">
import Vue, {PropType} from "vue";
import {countryCodes} from "@/services/view-helpers";

export default Vue.extend({
  name: "PhoneField",
  props: {
    required: Boolean,
    value: Object as PropType<{number: string, countryCode: string}>,
    disabled: Boolean,
  },
  computed: {
    rules(): ((v:string) => (boolean|string))[] {
      return this.required ? [this.requiredRule, this.phoneNumberRule] : [this.phoneNumberRule];
    },
    countryCodes(): string[] {
      return countryCodes;
    }
  },
  methods: {
    requiredRule(v: string): (boolean|string) {
      return !!v || 'This field is required';
    },
    phoneNumberRule(v: string): (boolean|string) {
      if (v) {
        if (!v.match(/^\d+$/)) {
          return 'Only include numbers';
        }
        if (v.length < 10 && this.value?.countryCode === '+1') {
          return 'Include area code and number';
        }
      }
      return true;
    },
    handleCountryCodeInput(countryCode: string) {
      this.$emit('input', {number: this.value.number, countryCode});
    },
    handleNumberInput(number: string) {
      this.$emit('input', {number, countryCode: this.value.countryCode});
    }
  }
});
</script>

<style scoped>
</style>
