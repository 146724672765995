<template>
  <v-form ref="form" @submit.prevent="submit" v-model="valid" lazy-validation>
    <v-alert type="error" v-if="error" dismissible>{{error}}</v-alert>
    <h3>Password reset</h3>
    <p class="mt-2 font-weight-light">Provide the email address associated to your account to initiate a password reset.</p>
    <p class="mt-2 font-weight-light">A verification code will be sent to the email address provided.</p>
    <v-text-field dense outlined v-model="email" :rules="rules" label="Email"/>
    <div class="alternate mt-3"><span class="font-weight-light mr-1">Never mind.</span>
      <a @click="handleBackToLogin">Back to login</a>
    </div>
    <div class="mt-5">
      <v-btn color="primary" type="submit" :loading="submitting">Request password reset</v-btn>
    </div>
  </v-form>
</template>

<script lang="ts">
import Vue from "vue";
import {AuthResponse, AuthStep} from "@/model/AuthTypes";
import FormElement from "@/model/FormElement";
import {Auth} from "aws-amplify";

export default Vue.extend({
  name: "ResetPasswordForm",
  data() {
    return {
      valid: true,
      submitting: false,
      error: '',
      rules: [(v:string) => !!v || 'This field is required'],

      email: '',
    }
  },
  computed: {
    formElement(): FormElement {
      return this.$refs.form as unknown as FormElement;
    },
  },
  methods: {
    validate () {
      return this.formElement.validate();
    },
    handleBackToLogin() {
      this.$emit('change', AuthStep.LOGIN);
    },
    async submit() {
      this.error = '';

      if (!this.validate()) {
        return;
      }

      try {
        this.submitting = true;
        const result: AuthResponse = await Auth.forgotPassword(this.email);
        // using the full email address in the next step
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        result.CodeDeliveryDetails = {...result.CodeDeliveryDetails!, DestinationFull: this.email};
        this.$emit('auth-response', result);
      } catch (e) {
        this.error = e.message;
        console.error('failed to request a password reset', e);
      } finally {
        this.submitting = false;
      }
    }
  }
});
</script>

<style scoped>
.alternate {
  font-size: .8em;
}
</style>
