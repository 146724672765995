import Vue from 'vue'
import Vuex from 'vuex'
import {Auth} from "aws-amplify";
import User from "@/model/User";
import {Route} from "vue-router";
import userService from "@/services/UserService";
import ReportPrecedence from "@/model/ReportPrecedence";
import reportService from "@/services/ReportService";
import UserProfile from "@/model/UserProfile";
import authHelper from '@/services/AuthHelper';
import clientService from "@/services/ClientService";
import Group from "@/model/Group";
import groupService from "@/services/GroupService";
import Role from "@/model/Role";

Vue.use(Vuex)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function cognitoUserToUser(cognitoUser: any) {
  if (!cognitoUser) return null;

  return {
    firstName: cognitoUser.attributes?.given_name ?? '',
    lastName: cognitoUser.attributes?.family_name ?? '',
    email: cognitoUser.attributes?.email ?? '',
    phoneNumber: cognitoUser.attributes?.phone_number ?? '',
  }
}

export enum DataLoadStatus {
  NOT_LOADED, LOADING, LOADED, ERROR,
}

export default new Vuex.Store({
  state: {
    user: null as (User|null),
    userProfile: null as (UserProfile|null),
    originalRoute: null as (Route|null),
    loadingUserProfile: false,
    errorLoadingUserProfile: false,
    reportPrecedence: {
      status: DataLoadStatus.NOT_LOADED,
      values: new Array<ReportPrecedence>(),
    },
    groups: {
      status: DataLoadStatus.NOT_LOADED,
      values: new Array<Group>(),
    },
  },
  mutations: {
    setCognitoUser(state, cognitoUser) {
      state.user = cognitoUserToUser(cognitoUser);
    },
    setOriginalRoute(state, route) {
      state.originalRoute = route;
    },
    setUserProfile(state, profile) {
      state.userProfile = profile;
    },
    setUserProfileEmailNameAndNumber(state, {email, firstName, lastName, phoneNumber}) {
      if (state.userProfile) {
        state.userProfile = {...state.userProfile, email, firstName, lastName, phoneNumber};
      }
    }
  },
  actions: {
    async initializeUser ({ commit, dispatch }) {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        commit('setCognitoUser', cognitoUser);
        if (cognitoUser) {
          dispatch('loadUserProfile');
        }
      } catch (e) {
        commit('setCognitoUser', null);
      }
    },
    async loadUserProfile ({ commit, state }) {
      try {
        state.loadingUserProfile = true;
        const profile = await userService.currentUserProfile();
        if (profile.requiredTosAcceptance) {
          profile.hasClientWithSftAccess = false;
        } else {
          if (authHelper.canSftFromClient(profile)) {
            // SFT from clients requires that a visible client has the SFT feature set to true
            const clients = await clientService.apiGetAllClients();
            profile.hasClientWithSftAccess = clients.some(c => c.secureFileTransfer);
          }
        }
        state.errorLoadingUserProfile = false;
        commit('setUserProfile', profile);
      } catch (e) {
        console.error('Failed to get the current user privileges. While the problem persists the user will have no privileges.');
        state.errorLoadingUserProfile = true;
        commit('setUserProfile', null);
      } finally {
        state.loadingUserProfile = false;
      }
    },
    async loginUser({commit, dispatch}, cognitoUser) {
      commit('setCognitoUser', cognitoUser);
      dispatch('loadUserProfile');
    },
    async logoutUser ({ commit }) {
      try {
        await Auth.signOut();
        commit('setCognitoUser', null);
      } catch (e) {
        console.error('failed to sign out');
        commit('setCognitoUser', null);
      }
    },
    async loadReportPrecedences ({ state }) {
      try {
        state.reportPrecedence.status = DataLoadStatus.LOADING;
        state.reportPrecedence.values = await reportService.getReportPrecedences();
        state.reportPrecedence.status = DataLoadStatus.LOADED;
      } catch (e) {
        console.error('failed to load report precedences', e);
        state.reportPrecedence.status = DataLoadStatus.ERROR;
      }
    },
    async loadGroupsIfNeeded ({ state }) {
      if (state.groups.status === DataLoadStatus.LOADED) {
        return;
      }

      try {
        state.groups.status = DataLoadStatus.LOADING;
        state.groups.values = await groupService.list();
        state.groups.status = DataLoadStatus.LOADED;
      } catch (e) {
        console.error('failed to load groups', e);
        state.groups.status = DataLoadStatus.ERROR;
      }
    },
  },
  getters: {
    requiredTosAcceptance(state) {
      return state.userProfile?.requiredTosAcceptance
    },
    isClient(state) {
      return state.userProfile && state.userProfile.role === Role.CLIENT_USER;
    }
  },
  modules: {
  }
})
