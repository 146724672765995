import Service from "@/services/Service";
import ReportPrecedence from "@/model/ReportPrecedence";
import IntelReport, {IntelReportForCreate} from "@/model/IntelReport";
import axios, {AxiosProgressEvent, AxiosRequestConfig, AxiosResponse} from "axios";
import ReportStatus from "@/model/ReportStatus";
import DataPage from "@/model/DataPage";

interface IntelReportCreatedResult {
    intelReport: IntelReport;
    signedPutUrl: string,
}

class ReportService extends Service {
    constructor() {
        super();
    }

    public async uploadReport(url: string, file: File, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void): Promise<void> {
        const config: AxiosRequestConfig = {};
        if (onUploadProgress) {
            config.onUploadProgress = onUploadProgress;
        }
        await axios.put(url, file, config);
    }

    public async createReport(report: IntelReportForCreate): Promise<IntelReportCreatedResult> {
        return (await this.axiosInstance.post<IntelReportForCreate, AxiosResponse<IntelReportCreatedResult>>('/intel-reports', report)).data;
    }

    public async getReportPrecedences(): Promise<ReportPrecedence[]> {
        return (await this.axiosInstance.get<ReportPrecedence[]>('/intel-reports/precedences')).data
    }

    public async getReportPage(limit: number, offset: number, sortBy: string, sortOrder: ('asc'|'desc'), search: string): Promise<DataPage<IntelReport>> {
        const params = new URLSearchParams([
            ['limit', limit.toString()],
            ['offset', offset.toString()],
            ['sortBy', sortBy],
            ['sortOrder', sortOrder],
            ['search', search],
        ]);
        return (await this.axiosInstance.get<DataPage<IntelReport>>('/intel-reports', { params })).data;
    }

    public async setUploadVerified(id: number): Promise<void> {
        await this.axiosInstance.put(`/intel-reports/${id}/upload-verified`);
    }

    public async getReportDownloadUrl(id: number): Promise<string> {
        return (await this.axiosInstance.get<{ signedGetUrl: string }>(`/intel-reports/${id}/download-url`)).data.signedGetUrl;
    }

    public async updateReport(id: number, report: IntelReportForCreate): Promise<IntelReportCreatedResult> {
        return (await this.axiosInstance.put<IntelReportCreatedResult>(`/intel-reports/${id}`, report)).data;
    }

    public async updateReportStatus(id: number, status: ReportStatus): Promise<IntelReport> {
        return (await this.axiosInstance.put<IntelReport>(`/intel-reports/${id}/status`, {reportStatus: status})).data;
    }

    public async deleteReport(id: number): Promise<void> {
        await this.axiosInstance.delete(`/intel-reports/${id}`);
    }

    public async updateReportAssignments(reportId: number, userProfileIds: number[]): Promise<void> {
        await this.axiosInstance.put<IntelReport>(`/intel-reports/${reportId}/assignment`, userProfileIds);
    }
}

const reportService = new ReportService();
export default reportService;
