<template>
  <div class="mt-5">
    <h4 class="text-h4">Secure File Transfer</h4>
    <v-alert v-if="error" type="error">{{ error }}</v-alert>

    <div class="mt-6 mb-3">
      <div class="d-flex justify-space-between align-baseline flex-wrap">
        <div v-if="canSftToClient(userProfile) || canSftFromClient(userProfile) "
             class="d-flex justify-space-between align-baseline flex-wrap">
          <v-btn color="primary" outlined data-test="new-sft-btn" class="mr-3" @click="showNewSftForm = true">New file
          </v-btn>
        </div>
        <div class="d-flex align-baseline flex-wrap">
          <div class="mr-6 mt-3">
            <ClientSelect label="Account Filter" v-model="clientId" dense clearable/>
          </div>
          <div class="search-field">
            <v-text-field
                data-test="search-field"
                class="mt-0"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                placeholder="search"
                clearable
            ></v-text-field>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="showNewSftForm" persistent max-width="800">
      <NewSftForm
          @cancel="showNewSftForm = false"
          @created="sftCreated"
          :routing-type="routingTypeForCreate"
      />
    </v-dialog>

    <SecureFileTransferList
        :search="search"
        :clientId="clientId"
        :loadingAllFiles="loadingAllFiles"
        :secureFileTransfers="secureFileTransfers"
        @marked-read="handleMarkedRead"
        @sftDeleted="sftDeleted"
    />
    <SnackbarMessage v-model="showCreateSuccess" data-test="success-message">File created successfully!</SnackbarMessage>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import SnackbarMessage from "@/components/SnackbarMessage.vue";
import AuthHelper from "@/services/AuthHelper";
import {mapState} from "vuex";
import NewSftForm from "@/components/NewSftForm.vue";
import {RoutingType, SecureFileTransfer} from "@/model/SecureFileTransfer";
import SecureFileTransferList from "@/components/SecureFileTransferList.vue";
import secureFileTransferService from "@/services/SecureFileTransferService";
import ClientSelect from "@/components/ClientSelect.vue";

export default Vue.extend({
  name: "SecureFileTransfers",
  components: {NewSftForm, SnackbarMessage, SecureFileTransferList, ClientSelect},
  data() {
    return {
      error: '',
      search: '',
      clientId: null as (number|null),
      showNewSftForm: false,
      showCreateSuccess: false,
      loadingAllFiles: false,
      secureFileTransfers: Array<SecureFileTransfer>()
    }
  },
  computed: {
    ...mapState(['user', 'userProfile']),
    routingTypeForCreate(): RoutingType {
      return this.canSftToClient(this.userProfile) ? RoutingType.TO_CLIENT : RoutingType.FROM_CLIENT;
    }
  },
  mounted() {
    if (this.canSftFromClient(this.userProfile) && !this.userProfile?.hasClientWithSftAccess) {
      // Client user does not have any client records with SFT access
      this.$router.push('/');
    }
  },
  created() {
    this.callApiGetAllSecureFileTransfers();
  },
  methods: {
    ...AuthHelper,
    sftCreated() {
      this.showNewSftForm = false;
      this.showCreateSuccess = true;
      this.callApiGetAllSecureFileTransfers();
    },
    async callApiGetAllSecureFileTransfers() {
      try {
        this.loadingAllFiles = true;
        this.secureFileTransfers = await secureFileTransferService.getSecureFileTransferList();
        this.error = '';
      } catch (e) {
        console.error('failed to load the secure file transfers', e);
        this.error = e.message;
      } finally {
        this.loadingAllFiles = false;
      }
    },
    handleMarkedRead(sft: SecureFileTransfer) {
      sft.isRead = true;
    },
    async sftDeleted() {
      await this.callApiGetAllSecureFileTransfers();
    }
  },
})

</script>

<style scoped>

</style>
