import axios, {AxiosInstance} from 'axios';
import {Auth} from "aws-amplify";

const axiosDefault = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
});

axiosDefault.interceptors.request.use(async function (config) {
    try {
        const session = await Auth.currentSession();
        config.headers['Authorization'] = session.getIdToken().getJwtToken();
        return config;
    } catch (e) {
        console.error('failed to set auth token: api call should fail');
        return config;
    }
});

axiosDefault.interceptors.response.use(function (response) {
    return response;
}, function(error) {
    console.error('API call error: ', error.response);
    if (error.response?.status === 401) {
        console.error('bad auth, reloading app to force login');
        location.reload();
    } else if (error.response?.data?.error === 'tos-not-accepted') {
        console.error('TOS requires acceptance, reloading app to force acceptance');
        location.reload();
    }
    return Promise.reject(error.response?.data ?? {});
});

export default abstract class Service {
    protected constructor(public axiosInstance: AxiosInstance = axiosDefault) {
    }
}
