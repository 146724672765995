<template>
  <v-form ref="form" @submit.prevent="submit" v-model="valid" lazy-validation>
    <v-alert type="error" v-if="error" dismissible>{{error}}</v-alert>
    <h3>Password reset</h3>
    <p class="mt-2 font-weight-light">Enter the verification code that has been sent to your email address and create a new password.</p>
    <v-text-field dense outlined v-model="code" :rules="[requiredRule]" label="Code"/>
    <v-text-field dense outlined v-model="newPassword" :rules="[requiredRule, passwordRule]" label="New password" type="password" validate-on-blur/>
    <v-text-field dense outlined v-model="newPasswordConfirmation" :rules="[requiredRule, passwordConfirmRule]" label="Retype new password" type="password" validate-on-blur/>
    <div class="alternate mt-3"><span class="font-weight-light mr-1">Didn't receive the email?</span>
      <a @click="handleBackToPasswordReset">Try a different email</a>
    </div>
    <div class="alternate mt-3"><span class="font-weight-light mr-1">Never mind.</span>
      <a @click="handleBackToLogin">Back to login</a>
    </div>
    <div class="mt-5">
      <v-btn color="primary" type="submit" :loading="submitting">Reset password</v-btn>
    </div>
  </v-form>
</template>

<script lang="ts">
import Vue, {PropType} from "vue";
import {AuthResponse, AuthStep} from "@/model/AuthTypes";
import FormElement from "@/model/FormElement";
import {Auth} from "aws-amplify";
import {passwordRule, requiredRule} from "@/services/ValidationRules";

export default Vue.extend({
  name: "ResetPasswordVerificationForm",
  props: {
    currentAuthRes: Object as PropType<AuthResponse>
  },
  data() {
    return {
      valid: true,
      submitting: false,
      error: '',
      code: '',
      newPassword: '',
      newPasswordConfirmation: '',
    }
  },
  computed: {
    formElement(): FormElement {
      return this.$refs.form as unknown as FormElement;
    },
    email(): string {
      return this.currentAuthRes.CodeDeliveryDetails?.Destination ?? '';
    }
  },
  methods: {
    requiredRule,
    passwordRule,
    passwordConfirmRule(v: string) {
      if (v !== this.newPassword) {
        return 'Does not match';
      }
      return true;
    },
    validate () {
      return this.formElement.validate();
    },
    handleBackToLogin() {
      this.$emit('change', AuthStep.LOGIN);
    },
    handleBackToPasswordReset() {
      this.$emit('change', AuthStep.RESET_PASSWORD);
    },
    async submit() {
      this.error = '';

      if (!this.validate()) {
        return;
      }

      try {
        this.submitting = true;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await Auth.forgotPasswordSubmit(this.currentAuthRes.CodeDeliveryDetails!.DestinationFull, this.code, this.newPassword);
        this.$emit('success');
      } catch (e) {
        if (e.message.match(/session is expired/i)) {
          this.$emit('session-expired');
        } else {
          this.error = e.message;
        }
        console.error('failure confirming sign in', e);
      } finally {
        this.submitting = false;
      }
    }
  }
});
</script>

<style scoped>
.alternate {
  font-size: .8em;
}
</style>
