import Vue from 'vue'
import VueRouter, {NavigationGuardNext, Route, RouteConfig} from 'vue-router'
import Health from "@/views/Health.vue";
import Reports from '@/views/Reports.vue'
import Login from "@/views/Login.vue";
import Admin from "@/views/admin/Admin.vue";
import AdminUsers from "@/views/admin/AdminUsers.vue";
import AdminInvites from "@/views/admin/AdminInvites.vue";
import AdminClients from "@/views/admin/AdminClients.vue";
import store from '@/store';
import Privilege from "@/model/Privilege";
import ProfileSettings from "@/views/ProfileSettings.vue";
import SecureFileTransfers from "@/views/SecureFileTransfers.vue";
import Redirect from "@/views/Redirect.vue";
import TermsOfService from "@/views/TermsOfService.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/',
        component: Redirect,
        beforeEnter: authGuard,
    },
    {
        path: '/health',
        name: 'Health',
        component: Health,
    },
    {
        path: '/reports',
        name: 'Reports',
        component: Reports,
        beforeEnter: authGuard,
    },
    {
        path: '/sft',
        name: 'SecureFileTransfers',
        component: SecureFileTransfers,
        beforeEnter: authGuard,
        meta: {
            privilegeReq: [Privilege.SFT_FROM_CLIENT, Privilege.SFT_TO_CLIENT]
        },
    },
    {
        path: '/admin',
        component: Admin,
        meta: {
            privilegeReq: [Privilege.ADMIN_CLIENTS, Privilege.ADMIN_USERS]
        },
        children: [
            {
                path: '',
                name: 'Admin',
                redirect: 'clients',
                beforeEnter: authGuard,
                meta: {
                    privilegeReq: [Privilege.ADMIN_CLIENTS]
                }
            },
            {
                path: 'users',
                name: 'AdminUsers',
                component: AdminUsers,
                beforeEnter: authGuard,
                meta: {
                    privilegeReq: [Privilege.ADMIN_USERS]
                }
            },
            {
                path: 'invites',
                name: 'AdminInvites',
                component: AdminInvites,
                beforeEnter: authGuard,
                meta: {
                    privilegeReq: [Privilege.ADMIN_USERS]
                }
            },
            {
                path: 'clients',
                name: 'AdminClients',
                component: AdminClients,
                beforeEnter: authGuard,
                meta: {
                    privilegeReq: [Privilege.ADMIN_CLIENTS]
                }
            },
        ]
    },
    {
        path: '/profile',
        name: 'Profile',
        component: ProfileSettings,
        beforeEnter: authGuard,
    },
    {
        path: '/tos',
        name: 'TOS',
        component: TermsOfService,
        beforeEnter: authGuard,
    },
]

function authGuard(to: Route, from: Route, next: NavigationGuardNext) {
    if (!store.state.user) {
        store.commit('setOriginalRoute', to);
        next({name: 'Login'});
        return;
    } else if (store.state.userProfile?.requiredTosAcceptance && to.name !== 'TOS') {
        store.commit('setOriginalRoute', to);
        next({name: 'TOS'});
        return;
    }
    next();
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
