<template>
  <v-container app :class="['mt-3', 'mb-16',  dense ? '' : 'px-8']" fluid>
    <slot/>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: {
    dense: Boolean
  }
})
</script>

<style scoped>
</style>
