<template>
  <div class="mt-5">
    <h4 class="text-h4">{{ this.getTitle() }}</h4>
    <v-alert v-if="error" type="error">{{ error }}</v-alert>

    <div class="my-6">
      <div class="d-flex justify-space-between align-baseline flex-wrap">
        <v-btn v-if="canCreateIntelReport(userProfile)" color="primary" outlined @click="showNewReportForm = true"
               data-test="new-report-btn" class="mr-3">
          New intel report
        </v-btn>
        <div class="search-field">
          <v-text-field
              data-test="search-field"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              placeholder="search"
              clearable
          ></v-text-field>
        </div>
      </div>
    </div>

    <v-dialog v-model="showNewReportForm" persistent max-width="800">
      <NewReportForm
          @cancel="showNewReportForm = false"
          @created="reportCreated"
      />
    </v-dialog>

    <ReportList
        :reports="reports"
        :total-count="totalCount"
        :loadingAllReports="loadingAllReports"
        :pagingOptions.sync="pagingOptions"
        @reportUpdated="reportUpdated"
        @reportDeleted="reportDeleted"/>

    <SnackbarMessage v-model="showCreateSuccess" data-test="success-message">Report created successfully!
    </SnackbarMessage>

    <SnackbarMessage v-model="showUpdateSuccess" data-test="update-message">Report updated successfully!
    </SnackbarMessage>

    <SnackbarMessage v-model="showSuccessWithUploadFailure" data-test="upload-fail-message" error>Report record saved but there was a problem uploading the PDF.
    </SnackbarMessage>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import NewReportForm from "@/components/NewReportForm.vue";
import SnackbarMessage from "@/components/SnackbarMessage.vue";
import ReportList from "@/components/ReportList.vue";
import IntelReport from "@/model/IntelReport";
import AuthHelper from "@/services/AuthHelper";
import {mapState} from "vuex";
import reportService from "@/services/ReportService";
import PagingOptions from "@/model/PagingOptions";
import _, { DebouncedFunc } from "lodash";

export default Vue.extend({
  name: "Reports",
  components: {ReportList, NewReportForm, SnackbarMessage},
  data() {
    return {
      file: null as (null | File),
      error: '',
      search: '',
      showNewReportForm: false,
      showCreateSuccess: false,
      showSuccessWithUploadFailure: false,
      showUpdateSuccess: false,
      loadingAllReports: true,
      reports: Array<IntelReport>(),
      totalCount: 0,
      pagingOptions: {
        sortBy: ['createdAt'],
        sortDesc: [false],
        itemsPerPage: 15,
        page: 1
      } as PagingOptions,
      debounceSearchWatcher: null as DebouncedFunc<(nv: string)=>void> | null,
      lastSearched: '',
    }
  },
  computed: {
    ...mapState(['user', 'userProfile']),
    isClient(): boolean {
      return this.$store.getters.isClient;
    }
  },
  watch: {
    pagingOptions: {
      handler (nv: PagingOptions, ov: PagingOptions) {
        if (nv.page !== ov.page || nv.itemsPerPage !== ov.itemsPerPage || nv.sortDesc[0] !== ov.sortDesc[0] || nv.sortBy[0] !== ov.sortBy[0]) {
          this.callApiGetAllReports()
        }
      },
      deep: true,
    },
    search(nv: string) {
      if (this.debounceSearchWatcher) {
        this.debounceSearchWatcher(nv);
      }
    }
  },
  methods: {
    ...AuthHelper,
    handleSearch(nv: string) {
      if (nv !== this.lastSearched) {
        if (this.pagingOptions.page !== 1) {
          this.pagingOptions = {...this.pagingOptions, page: 1}; //the watcher on options will trigger the new search
        } else {
          this.callApiGetAllReports();
        }
      }
    },
    reportCreated(report: IntelReport, uploadSuccess?: boolean) {
      this.showNewReportForm = false;
      if (uploadSuccess === false) {
        this.showSuccessWithUploadFailure = true;
      } else {
        this.showCreateSuccess = true;
      }
      this.callApiGetAllReports();
    },
    reportUpdated(updatedReport: IntelReport, uploadSuccess?: boolean) {
      const currentReport = this.reports.find(report => report.id === updatedReport.id);
      if (currentReport && currentReport.clientId === updatedReport.clientId) {
        Object.assign(currentReport, updatedReport);
      } else {
        // Consider getting just the updated report from the server in this
        // case and replacing it if it becomes a perf issue. This call is made
        // because the clientDisplayName needs to be re-queried if the client is changed
        this.callApiGetAllReports();
      }
      if (uploadSuccess === false) {
        this.showSuccessWithUploadFailure = true;
      } else {
        this.showUpdateSuccess = true;
      }
    },
    reportDeleted(id: number) {
      this.reports.splice(this.reports.findIndex(report => report.id === id),1);
    },
    async callApiGetAllReports() {
      try {
        this.loadingAllReports = true;
        const page = await reportService.getReportPage(
            this.pagingOptions.itemsPerPage,
            (this.pagingOptions.page-1) * this.pagingOptions.itemsPerPage,
            this.pagingOptions.sortBy[0],
            this.pagingOptions.sortDesc[0] ? 'desc' : 'asc',
            this.search ?? '',
        );
        this.lastSearched = this.search;
        this.reports = page.data;
        this.totalCount = page.totalCount;
        this.error = '';
      } catch (e) {
        console.error('failed to load the reports', e);
        this.error = 'There was an error loading the reports';
      } finally {
        this.loadingAllReports = false;
      }
    },
    getTitle() {
      if (this.canCreateIntelReport(this.userProfile)) {
        return "Report Console";
      } else {
        return "Intel Report List";
      }
    }
  },
  created() {
    if (this.isClient) {
      this.pagingOptions.sortBy = ['releasedAt'];
      this.pagingOptions.sortDesc = [true];
    }

    this.callApiGetAllReports();

    this.debounceSearchWatcher = _.debounce((nv: string) => {
      this.handleSearch(nv);
    }, 700);
  },
  beforeDestroy() {
    this.debounceSearchWatcher?.cancel();
  }
})

</script>

<style scoped>

</style>
