<template>
  <div>
    <v-select
        :value="value"
        @input="$emit('input', $event)"
        :items="roleOptions"
        label="Role"
        outlined
        :rules="rules"
        name="role"
        :dense="dense"
        :disabled="disabled"
    ></v-select>
  </div>
</template>

<script lang="ts">
import Vue, {PropType} from "vue";
import Role, {roleOptions} from "@/model/Role";

export default Vue.extend({
  name: "RoleSelect",
  props: {
    value: [Object, String] as PropType<Role>,
    rules: Array,
    dense: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      roleOptions
    }
  }
});
</script>

<style scoped>

</style>
