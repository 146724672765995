<template>
  <v-form ref="form" @submit.prevent="submit" v-model="valid" lazy-validation>
    <v-alert type="error" v-if="error" dismissible>{{error}}</v-alert>
    <h3 class="mb-5">Login</h3>
    <v-text-field dense outlined autofocus v-model.trim="username" :rules="[requiredRule]" label="Email"/>
    <v-text-field dense outlined type="password" v-model.trim="password" :rules="[requiredRule]" label="Password"/>
    <div class="forgot-section mt-3"><span class="font-weight-light mr-1">Forgot your password?</span>
      <a @click="handleResetPassword">Reset password</a>
    </div>
    <div class="mt-5">
      <v-btn color="primary" type="submit" :loading="submitting">Login</v-btn>
    </div>
  </v-form>
</template>

<script lang="ts">
import Vue from "vue";
import {Auth} from "aws-amplify";
import FormElement from "@/model/FormElement";
import {AuthStep} from "@/model/AuthTypes";
import {requiredRule} from "@/services/ValidationRules";

export default Vue.extend({
  name: "LoginForm",
  data() {
    return {
      valid: true,

      submitting: false,
      error: '',

      username: '',
      password: '',
    }
  },
  computed: {
    formElement(): FormElement {
      return this.$refs.form as unknown as FormElement;
    },
  },
  methods: {
    requiredRule,
    validate () {
      return this.formElement.validate();
    },
    async submit() {
      this.error = '';
      if (!this.validate()) {
        return;
      }

      try {
        this.submitting = true;
        const result = await Auth.signIn(this.username, this.password);
        this.$emit('auth-response', result);
      } catch (e) {
        console.error('login failure', e);
        let msg = e.message;
        if (e.message.match(/user is disabled/i)) {
          msg = 'Your account is disabled - contact us at intelink@cybeta.com';
        }
        this.error = msg;
      } finally {
        this.submitting = false;
      }
    },
    handleResetPassword() {
      this.$emit('change', AuthStep.RESET_PASSWORD);
    }
  }
});
</script>

<style scoped>
  .forgot-section {
    font-size: .8em;
  }
</style>
