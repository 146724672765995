<template>
  <v-card>
    <v-alert v-if="error" type="error" dismissible>{{error}}</v-alert>
    <v-card-title class="primary--text">
      <div class="title">
        <div>Notification Preferences</div>
        <v-btn v-if="!editing" color="primary" outlined small @click="editing=true" data-test="edit-prefs-btn">Edit</v-btn>
      </div>
    </v-card-title>
    <v-divider/>
    <v-progress-linear v-if="loading" indeterminate color="accent"/>
    <v-card-text class="mb-6">
      <div v-if="!loading && !error">
        <div>
          <p>The following indicates when you will be notified through email for new reports of the corresponding precedence level.</p>
          <div class="preferences">
            <div class="overline">Precedence</div><div class="overline notify">Notify</div>
            <v-divider class="mb-1"/><v-divider class="mb-1"/>

            <template v-for="pref in profileNotificationPrefs.notificationsPrefs">
              <div :key="pref.reportPrecedenceName">{{pref.reportPrecedenceName}}</div>
              <div :key="pref.reportPrecedenceId" class="notify">
                <v-switch v-if="editing" class="my-0" :input-value="editedPref(pref.reportPrecedenceId).notify"
                          @change="v => updateEditedValue(pref.reportPrecedenceId, v)"
                          dense color="success" hide-details/>
                <template v-else>
                  <v-icon v-if="pref.notify" color="success" data-test="notify-yes">mdi-check</v-icon>
                  <span v-else data-test="notify-no">&mdash;&mdash;</span>
                </template>
              </div>
            </template>
          </div>
          <template v-if="editing">
            <v-checkbox color="success" label="Apply notification preferences to child accounts" v-model="editedPrefs.applyToChildClients" hide-details />
          </template>
          <template v-else>
            <div class="mt-5" data-test="apply-to-child-note">
              <span v-if="profileNotificationPrefs.applyToChildClients" ><span class="yes">YES</span>, </span>
              <span v-else><span class="no">NO</span>, do not </span>
              <span class="mr-1">apply notification preferences to child accounts</span>
            </div>
          </template>
        </div>
      </div>
    </v-card-text>
    <v-divider v-if="editing"/>
    <v-card-actions class="d-flex justify-end pa-4" v-if="editing">
      <v-btn class="mr-4" @click="cancelEdits" data-test="cancel-prefs-btn">Cancel</v-btn>
      <v-btn color="primary" @click="save" :loading="saving" data-test="save-prefs-btn">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Vue, {PropType} from "vue";
import UserProfile from "@/model/UserProfile";
import userService from "@/services/UserService";
import {NotificationPref, ProfileNotificationPrefs} from "@/model/NotificationPrefs";

export default Vue.extend({
  name: "NotificationPrefs",
  props: {
    userProfile: Object as PropType<UserProfile>,
  },
  data() {
    return {
      loading: true,
      profileNotificationPrefs: null as (null|ProfileNotificationPrefs),
      editedPrefs: null as (null|ProfileNotificationPrefs),
      error: '',
      editing: false,
      saving: false,
    }
  },
  computed: {
  },
  mounted() {
    this.lookupNotificationPrefs();
  },
  methods: {
    async lookupNotificationPrefs() {
      try {
        this.loading = true;
        this.profileNotificationPrefs = await userService.currentUserNotificationPrefs();
        this.resetEditedPrefs();
      } catch (e) {
        this.error = 'Failed to lookup notification preferences';
        console.error('failed to lookup notification preferences', e);
      } finally {
        this.loading = false;
      }
    },
    resetEditedPrefs() {
      if (!this.profileNotificationPrefs) {
        this.editedPrefs = null;
      } else {
        this.editedPrefs = {
          ...this.profileNotificationPrefs,
          notificationsPrefs: this.profileNotificationPrefs.notificationsPrefs.map(p => ({...p}))
        };
      }
    },
    updateEditedValue(precedenceId: number, value: boolean) {
      if (this.editedPrefs) {
        const pref = this.editedPrefs.notificationsPrefs.find(p => p.reportPrecedenceId === precedenceId);
        if (pref) {
          pref.notify = value;
        }
      }
    },
    cancelEdits() {
      this.editing = false;
      this.resetEditedPrefs();
    },
    editedPref(precedenceId: number): (NotificationPref|undefined) {
      return this.editedPrefs?.notificationsPrefs?.find(p => p.reportPrecedenceId === precedenceId)
    },
    async save() {
      if (!this.editedPrefs) {
        return;
      }

      try {
        this.saving = true;
        this.error = '';
        await userService.updateCurrentUserNotificationPrefs(this.editedPrefs);
        this.profileNotificationPrefs = this.editedPrefs;
        this.editing = false;
        this.resetEditedPrefs();
      } catch (e) {
        console.error('failed to update preferences', e);
        this.error = 'Failed to save preferences';
      } finally {
        this.saving = false;
      }
    }
  }
})
</script>

<style scoped>
  table td, table th {
    text-align: center;
    padding: 5px 10px;
  }

  .preferences {
    display: grid;
    grid-template-columns: 130px 130px;
  }

  .preferences .notify {
    justify-self: center;
  }

  .title {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .yes, .no {
    font-weight: bold;
  }

  .yes {
    color: var(--v-success-base);
  }

</style>
