<template>
  <v-list class="nav-list py-0">
    <v-list-item v-if="canAdminUsersOrClients(userProfile)" :to="adminLink" data-test="admin-link">
      <v-list-item-icon class="mr-4"><v-icon color="primary">mdi-account-multiple</v-icon></v-list-item-icon>
      <v-list-item-title class="primary--text">Admin</v-list-item-title>
    </v-list-item>
    <v-list-item to="/reports">
      <v-list-item-icon class="mr-4"><v-icon color="primary">mdi-file-chart</v-icon></v-list-item-icon>
      <v-list-item-title class="primary--text">Reports</v-list-item-title>
    </v-list-item>
    <v-list-item v-if="canSft" to="/sft" data-test="sft-nav">
      <v-list-item-icon class="mr-4"><v-icon color="primary">mdi-file-key</v-icon></v-list-item-icon>
      <v-list-item-title class="primary--text">Secure File Xfer</v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import Vue from "vue";
import {mapState} from "vuex";
import AuthHelper from "@/services/AuthHelper";

export default Vue.extend({
  name: "NavMenu",
  computed: {
    ...mapState(['userProfile']),
    adminLink(): string {
      if (this.$route.name === 'AdminUsers') {
        return '/admin/users';
      } else if (this.$route.name === 'AdminClients') {
        return '/admin/clients';
      } else if (this.$route.name === 'AdminInvites') {
        return '/admin/invites';
      } else {
        return this.canAdminClients(this.userProfile) ? '/admin/clients' : '/admin/users';
      }
    },
    canSft(): boolean {
      if (this.canSftFromClient(this.userProfile)) {
        return this.userProfile?.hasClientWithSftAccess;
      } else {
        return this.canSftToClient(this.userProfile);
      }
    }
  },
  methods: {
    ...AuthHelper
  }
})
</script>

<style scoped>

</style>
