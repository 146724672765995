import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { Amplify } from 'aws-amplify'
import authconfig from './auth-config';

Amplify.configure(authconfig);

Vue.config.productionTip = false

store.dispatch('initializeUser').then(() => {
  console.log(`-----------------------------------------------\n Cybeta INTELink\n build:${process.env.VUE_APP_VERSION}\n-----------------------------------------------`);
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
});
