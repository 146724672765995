export function passwordRule(v: string): boolean|string {
    if (v.length < 8) {
        return 'Must be at least 8 characters long';
    }
    if (!v.match(/[a-z]/)) {
        return 'Must include a lowercase letter';
    }
    if (!v.match(/[A-Z]/)) {
        return 'Must include a capital letter';
    }
    if (!v.match(/[0-9]/)) {
        return 'Must include a number';
    }
    if (!v.match(/[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/)) {
        return 'Must include a special character';
    }
    return true;
}

export function requiredRule(v:string): boolean|string {
    return !!v && !!(v.trim()) || 'This field is required';
}

export function emailRule(v: string): boolean|string {
    return v === '' || /.+@.+\..+/.test(v) || 'invalid email';
}
