<template>
  <span class="download-link">
    <a href="#" @click.prevent="download">
      <slot>{{name}}</slot>
    </a>
    <SnackbarMessage v-model="showError" error>Failed to download the {{fileType === 'report' ? 'report' : 'file'}}</SnackbarMessage>
  </span>
</template>

<script lang="ts">
import Vue, {PropType} from "vue";
import reportService from "@/services/ReportService";
import SnackbarMessage from "@/components/SnackbarMessage.vue";
import secureFileTransferService from "@/services/SecureFileTransferService";

export default Vue.extend({
  name: "DownloadLink",
  components: {SnackbarMessage},
  props: {
    name: String,
    id: Number,
    fileType: {
      type: String as PropType<'report' | 'sft' >,
      required: true,
    }
  },
  data() {
    return {
      busy :false,
      showError: false,
    }
  },
  methods: {
    async download() {
      if (this.busy) {
        return;
      }
      try {
        this.showError = false;
        this.busy = true;

        let url: string;
        if (this.fileType === 'report') {
          url = await reportService.getReportDownloadUrl(this.id);
        } else {
          const result = await secureFileTransferService.getFileDownloadUrl(this.id);
          url = result.signedGetUrl;
          if (result.markedAsRead) {
            this.$emit('marked-read');
          }
        }

        const link = document.createElement('a');
        link.href = url;
        link.click();
      } catch (e) {
        console.error('failed to get a secure download link', e);
        this.showError = true;
      } finally {
        this.busy = false;
      }
    }
  }
});
</script>

<style scoped>
  a {
    text-decoration: none;
  }
</style>
