enum Role {
    ADMIN = 'INTELink_internal_admins',
    INTERNAL_USER = 'INTELink_internal_users',
    CLIENT_USER = 'INTELink_client_users',
}

export const roleOptions = Object.entries(Role).map(([k, v]) => ({
  value: v as Role,
  text: k.replace(/_/g, ' '),
}))

export default Role
