<template>
  <div class="auth">
    <v-alert type="success" v-if="successMessage" dismissible>{{successMessage}}</v-alert>
    <v-alert type="error" v-if="errorMessage" dismissible>{{errorMessage}}</v-alert>
    <template v-if="isStep(StepLogin)">
      <LoginForm @change="changeStep" @auth-response="handleAuthResponse"/>
    </template>

    <template v-if="isStep(StepMfa)">
      <MfaForm @change="changeStep" :current-auth-res="currentAuthResponse" @auth-response="handleAuthResponse" @session-expired="handleExpiredSession"/>
    </template>

    <template v-if="isStep(StepResetPassword)">
      <ResetPasswordForm @change="changeStep" @auth-response="handleAuthResponse"/>
    </template>

    <template v-if="isStep(StepResetPasswordVerification)">
      <ResetPasswordVerificationForm @change="changeStep" :current-auth-res="currentAuthResponse" @success="handlePasswordResetSuccess" @session-expired="handleExpiredSession"/>
    </template>

    <template v-if="isStep(StepCompleteProfile)">
      <CompleteProfileForm :current-auth-res="currentAuthResponse" @auth-response="handleAuthResponse" @session-expired="handleExpiredSession"/>
    </template>

  </div>
</template>

<script lang="ts">
import Vue from "vue";
import LoginForm from "@/components/auth/LoginForm.vue";
import MfaForm from "@/components/auth/MfaForm.vue";
import {AuthResponse, AuthStep} from "@/model/AuthTypes";
import {Auth} from "aws-amplify";
import ResetPasswordForm from "@/components/auth/ResetPasswordForm.vue";
import ResetPasswordVerificationForm from "@/components/auth/ResetPasswordVerificationForm.vue";
import CompleteProfileForm from "@/components/auth/CompleteProfileForm.vue";

export default Vue.extend({
  name: "Authenticator",
  components: {CompleteProfileForm, ResetPasswordVerificationForm, MfaForm, ResetPasswordForm, LoginForm},
  data() {
    return {
      state: AuthStep.LOGIN as AuthStep,
      currentAuthResponse: null as null|AuthResponse,
      successMessage: '',
      errorMessage: '',
    }
  },
  computed: {
    StepLogin: () => AuthStep.LOGIN,
    StepMfa: () => AuthStep.MFA,
    StepResetPassword: () => AuthStep.RESET_PASSWORD,
    StepResetPasswordVerification: () => AuthStep.RESET_PASSWORD_VERIFICATION,
    StepCompleteProfile: () => AuthStep.COMPLETE_PROFILE,
  },
  methods: {
    isStep(s: AuthStep): boolean {
      return s === this.state;
    },
    changeStep(s: AuthStep) {
      this.successMessage = '';
      this.errorMessage = '';
      this.state = s;
    },
    async handleAuthResponse(authResponse: AuthResponse) {
      this.errorMessage = '';
      this.successMessage = '';

      this.currentAuthResponse = authResponse;

      // Successful login
      if (authResponse.signInUserSession) {
        console.log('successful sign in');
        const user = await Auth.currentAuthenticatedUser();
        this.$emit('signed-in', user);
        this.state = AuthStep.SIGNED_IN;
        return;
      }

      // Further challenge
      if (authResponse.challengeName) {
        if (authResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.state = AuthStep.COMPLETE_PROFILE;
        } else if (authResponse.challengeName === 'SMS_MFA') {
          this.state = AuthStep.MFA;
        }
        return;
      }

      // Reset password code sent
      if (authResponse.CodeDeliveryDetails) {
        this.state = AuthStep.RESET_PASSWORD_VERIFICATION;
      }
    },
    handlePasswordResetSuccess() {
      this.errorMessage = '';
      this.successMessage = '';
      this.successMessage = 'Password changed successfully. Please log in with your new password.';
      this.state = AuthStep.LOGIN;
    },
    handleExpiredSession() {
      this.errorMessage = '';
      this.successMessage = '';
      this.errorMessage = 'Sorry, the login session has expired. Please try again.';
      this.state = AuthStep.LOGIN;
    }
  }
});
</script>

<style scoped>
.auth {
  max-width: 500px;
}
</style>
