import { render, staticRenderFns } from "./SecureFileTransferList.vue?vue&type=template&id=7f5ec6b6&scoped=true"
import script from "./SecureFileTransferList.vue?vue&type=script&lang=ts"
export * from "./SecureFileTransferList.vue?vue&type=script&lang=ts"
import style0 from "./SecureFileTransferList.vue?vue&type=style&index=0&id=7f5ec6b6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f5ec6b6",
  null
  
)

export default component.exports