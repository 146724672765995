import Service from "@/services/Service";
import axios, {AxiosProgressEvent, AxiosRequestConfig, AxiosResponse} from "axios";
import {SecureFileTransfer, SecureFileTransferForCreate} from "@/model/SecureFileTransfer";

interface SftCreatedResult {
    secureFileTransfer: SecureFileTransfer;
    signedPutUrl: string,
}

export interface SftSignedGetUrlResult {
    signedGetUrl: string,
    markedAsRead: boolean,
}

class SecureFileTransferService extends Service {
    constructor() {
        super();
    }

    public async uploadFile(url: string, file: File, onUploadProgress?: (progressEvent: AxiosProgressEvent)=>void): Promise<void> {
        const config: AxiosRequestConfig = {};
        if (onUploadProgress) {
            config.onUploadProgress = onUploadProgress;
        }
        await axios.put(url, file, config);
    }

    public async createSft(sft: SecureFileTransferForCreate): Promise<SftCreatedResult[]> {
        return (await this.axiosInstance.post<SecureFileTransferForCreate, AxiosResponse<SftCreatedResult[]>>('/sft', sft)).data;
    }

    public async setUploadVerified(id: number): Promise<void> {
        await this.axiosInstance.put(`/sft/${id}/upload-verified`);
    }

    public async getSecureFileTransferList(): Promise<SecureFileTransfer[]> {
        return (await this.axiosInstance.get<SecureFileTransfer[]>('/sft')).data
    }

    public async getFileDownloadUrl(id: number): Promise<SftSignedGetUrlResult> {
        return (await this.axiosInstance.get<SftSignedGetUrlResult>(`/sft/${id}/download-url`)).data;
    }

    public async deleteSft(id: number): Promise<void> {
        await this.axiosInstance.delete(`/sft/${id}`);
    }
}

const secureFileTransferService = new SecureFileTransferService();
export default secureFileTransferService;
