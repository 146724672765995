enum ReportStatus {
    PENDING_REVIEW = 'pending-review',
    NEW = 'new',
    READ = 'read',
    REMEDIATION_SCHEDULED = 'remediation-scheduled',
    REMEDIATION_IN_PROGRESS = 'remediation-in-progress',
    REMEDIATION_COMPLETE = 'remediation-complete',
    RISK_ACCEPTED = 'risk-accepted',
    VENDOR_NOTIFIED = 'vendor-notified',
    NOT_APPLICABLE = 'not-applicable',
}

export const reportStatusOptions = [
    {key: ReportStatus.PENDING_REVIEW, value: 'Pending review'},
    {key: ReportStatus.NEW, value: 'New'},
    {key: ReportStatus.READ, value: 'Read'},
    {key: ReportStatus.REMEDIATION_SCHEDULED, value: 'Remediation scheduled'},
    {key: ReportStatus.REMEDIATION_IN_PROGRESS, value: 'Remediation in progress'},
    {key: ReportStatus.REMEDIATION_COMPLETE, value: 'Remediation complete'},
    {key: ReportStatus.RISK_ACCEPTED, value: 'Risk accepted'},
    {key: ReportStatus.VENDOR_NOTIFIED, value: 'Vendor notified'},
    {key: ReportStatus.NOT_APPLICABLE, value: 'Not applicable'},
]

export default ReportStatus;
