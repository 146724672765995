<template>
  <v-card>
    <v-alert v-if="error" type="error" class="ma-0" rounded="0" dismissible data-test="error-alert">{{error}}</v-alert>
    <v-form @submit.prevent="save" class="pa-4" ref="form" v-model="valid" lazy-validation>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <div class="mb-5 overline">
          Name &amp; Base Information
        </div>
        <v-row>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-text-field
                v-model.trim="name"
                label="Client Name"
                dense
                outlined
                required
                @blur="autofillDisplayName()"
                :rules="[requiredRule]"
                data-test="clientNameInput"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-text-field
                v-model.trim="displayName"
                label="Display Name"
                dense
                outlined
                required
                :rules="[requiredRule]"
                data-test="displayNameInput"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-text-field
                v-model.trim="topLevelDomain"
                label="Top Level Domain"
                dense
                outlined
                data-test="topLevelDomainInput"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-select
                v-model="clientType"
                label="Client Type"
                dense
                outlined
                required
                :rules="[requiredRule]"
                :items="clientTypeChoices"
                data-test="clientTypeInput"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <ClientSelect ref="clientSelector" v-model="parentClientId"
                          :disable-children-of="clientToEdit ? clientToEdit.id : undefined"
                          :multiple="false" label="Parent client" dense clearable data-test="clientSelectInput"/>
          </v-col>
        </v-row>
        <div class="mb-5 overline">
          Address
        </div>
        <v-row>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-text-field
                v-model.trim="city"
                label="City"
                dense
                outlined
                data-test="cityInput"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-text-field
                v-model.trim="stateProvince"
                label="State/Province"
                dense
                outlined
                data-test="stateProvinceInput"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-text-field
                v-model.trim="postalCode"
                label="Zip"
                dense
                outlined
                data-test="postalCodeInput"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-text-field
                v-model.trim="country"
                label="Country"
                dense
                outlined
                data-test="countryInput"
            ></v-text-field>
          </v-col>
        </v-row>
        <div class="mb-5 overline">
          Subscription Details
        </div>
        <v-row>
          <v-col cols="12" sm="4" md="2" class="py-0">
            <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                data-test="startDateMenuInput"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="computedStartDateFormatted"
                    label="Start Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    clearable
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    data-test="startDateReadOnlyText"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="contractStart"
                  @input="dateUpdated('start')"
                  data-test="startDatePicker"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" md="2" class="py-0">
            <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                data-test="endDateMenuInput"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="computedEndDateFormatted"
                    label="End Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    clearable
                    dense
                    outlined
                    :rules="[afterStartDateRule]"
                    v-bind="attrs"
                    v-on="on"
                    data-test="endDateReadOnlyText"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="contractEnd"
                  @input="dateUpdated('end')"
                  data-test="endDatePicker"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="pl-sm-8 py-0">
            <v-switch
                class="mt-0"
                v-model="ndaStatus"
                dense
                outlined
                label="NDA Status"
                offset-y
                data-test="ndaStatusInput"
            >
            </v-switch>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="pl-sm-8 py-0">
            <v-switch
                class="mt-0"
                v-model="secureFileTransfer"
                label="Secure File Xfer"
                dense
                outlined
                data-test="secureFileTransferInput"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="pl-sm-8 py-0">
            <v-switch
                class="mt-0"
                v-model="humanIntelReports"
                label="OverWatch Reports"
                dense
                outlined
                data-test="humanIntelReportsInput"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="pl-sm-8 py-0">
            <v-switch
                class="mt-0"
                v-model="threatCollection"
                label="Threat Collection"
                dense
                outlined
                data-test="threatCollectionInput"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-select
                v-model="automatedAnalysis"
                label="Threat Collection Engine"
                dense
                outlined
                :rules="[automatedAnalysisRule]"
                :items="autoAnalysisChoices"
                :disabled=!threatCollection
                data-test="automatedAnalysisInput"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-select
                v-model="refreshInterval"
                label="Refresh Interval"
                dense
                outlined
                clearable
                :disabled=!threatCollection
                :rules="[refreshIntervalRule]"
                :items="refreshChoices"
                data-test="refreshIntervalInput"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel" type="button" :disabled="saving" data-test="cancelEdit">Cancel</v-btn>
        <v-btn color="primary" type="submit" :loading="saving">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { PropType } from "vue/types/umd";
import {Client, ClientForSave} from "@/model/Client";
import clientService from "@/services/ClientService";
import FormElement from "@/model/FormElement";
import {requiredRule} from "@/services/ValidationRules";
import {formatIsoDateToMmDdYyyy} from "@/services/view-helpers";
import ClientSelect from "@/components/ClientSelect.vue";

export default Vue.extend({
  name: "ClientForm",
  components: {ClientSelect},
  props: {
    clientToEdit: Object as PropType<Client>,
  },
  data() {
    return {
      error: '',
      valid: true,
      saving: false,
      startDateMenu: false,
      endDateMenu: false,

      clientTypeChoices: ['Business Development', 'Commercial'],
      autoAnalysisChoices: [
        {text: 'Threat Beta', value: 'Threat Beta'},
        {text: 'Threat Beta Plus', value: 'Threat Beta Plus'}
      ],
      refreshChoices: ['30', '60', '90', '120'],

      threatCollection: false,
      name: '',
      displayName: '',
      clientType: '',
      city: '',
      stateProvince: '',
      country: '',
      postalCode: '',
      topLevelDomain: '',
      ndaStatus: false,
      contractStart: null as (null|string),
      contractEnd: null as (null|string),
      primaryPocId: null as (null|number),
      cybetaAccountManagerId: null as (null|number),
      secureFileTransfer: false,
      humanIntelReports: false,
      automatedAnalysis: 'None',
      refreshInterval: null as (null|string),
      parentClientId: null as (null|number),
    }
  },
  computed: {
    formTitle(): string {
      return this.isEdit ? 'Edit Client' : 'New Client';
    },
    formElement(): FormElement {
      return this.$refs.form as unknown as FormElement;
    },
    isEdit(): boolean {
      return !!this.clientToEdit;
    },
    clientSelector(): { loadClients: () => Promise<void> } {
      return this.$refs.clientSelector as unknown as { loadClients: () => Promise<void> };
    },
    editedItem(): ClientForSave {
      return {
        id: this.clientToEdit?.id ?? undefined,
        name: this.name,
        displayName: this.displayName,
        clientType: this.clientType,
        city: this.city,
        stateProvince: this.stateProvince,
        country: this.country,
        postalCode: this.postalCode,
        topLevelDomain: this.topLevelDomain,
        ndaStatus: this.ndaStatus,
        contractStart: this.contractStart,
        contractEnd: this.contractEnd,
        primaryPocId: this.primaryPocId,
        cybetaAccountManagerId: this.cybetaAccountManagerId,
        secureFileTransfer: this.secureFileTransfer,
        humanIntelReports: this.humanIntelReports,
        automatedAnalysis: this.automatedAnalysis,
        refreshInterval: this.refreshInterval,
        parentClientId: this.parentClientId,
      }
    },
    computedStartDateFormatted(): string | null {
      if (!this.contractStart) {
        return null;
      }
      return this.formatDate(this.contractStart)
    },
    computedEndDateFormatted(): string | null {
      if (!this.contractEnd) {
        return null;
      }
      return this.formatDate(this.contractEnd)
    },
  },
  mounted() {
    this.resetForm();
  },
  watch: {
    clientToEdit() {
      this.resetForm();
    },
  },
  methods: {
    requiredRule,
    afterStartDateRule() {
      if ((!this.contractStart || !this.contractEnd) || this.contractEnd > this.contractStart) {
        return true
      } else {
        return 'Must come after start date';
      }
    },
    automatedAnalysisRule(v: string) {
      if (this.threatCollection && (!v || this.automatedAnalysis === 'None')) {
        return 'TCE is required when Threat Collection is chosen'
      } else {
        return true
      }
    },
    refreshIntervalRule(v: string) {
      if (!v && this.$data.threatCollection) {
        return 'refresh interval is required when Threat Collection is chosen'
      } else {
        return true
      }
    },
    resetForm() {
      this.formElement.resetValidation();

      this.threatCollection = this.clientToEdit && this.clientToEdit.automatedAnalysis !== 'None';
      this.name = this.clientToEdit?.name ?? '';
      this.displayName = this.clientToEdit?.displayName ?? '';
      this.clientType = this.clientToEdit?.clientType ?? '';
      this.city = this.clientToEdit?.city ?? '';
      this.stateProvince = this.clientToEdit?.stateProvince ?? '';
      this.country = this.clientToEdit?.country ?? '';
      this.postalCode = this.clientToEdit?.postalCode ?? '';
      this.topLevelDomain = this.clientToEdit?.topLevelDomain ?? '';
      this.ndaStatus = this.clientToEdit?.ndaStatus ?? false;
      this.contractStart = this.clientToEdit?.contractStart ?? null;
      this.contractEnd = this.clientToEdit?.contractEnd ?? null;
      this.primaryPocId = this.clientToEdit?.primaryPocId ?? null;
      this.cybetaAccountManagerId = this.clientToEdit?.cybetaAccountManagerId ?? null;
      this.secureFileTransfer = this.clientToEdit?.secureFileTransfer ?? false;
      this.humanIntelReports = this.clientToEdit?.humanIntelReports ?? true;
      this.automatedAnalysis = this.clientToEdit?.automatedAnalysis ?? 'None';
      this.refreshInterval = this.clientToEdit?.refreshInterval ?? null;
      this.parentClientId = this.clientToEdit?.parentClientId ?? null;
    },
    autofillDisplayName() {
      if (!this.displayName) {
        this.displayName = this.name;
      }
    },
    validate(): boolean {
      return this.formElement.validate();
    },
    cancel() {
      this.$emit('cancel');
      this.resetForm();
      this.error = '';
    },
    async save() {
      if (!this.validate()) {
        return;
      }

      this.error = '';
      this.saving = true;
      try {
        if (!this.threatCollection) {
          this.automatedAnalysis = 'None';
          this.refreshInterval = null;
        }

        let saved: Client;
        if (this.isEdit) {
          saved = await clientService.apiPutClient(this.editedItem);
        } else {
          saved = await clientService.apiPostClient(this.editedItem);
        }
        this.$emit('saved', saved);
        if (this.$refs.clientSelector) {
          // force ClientSelect to reload
          this.clientSelector.loadClients().then();
        }
        this.resetForm();
      } catch (e) {
        console.error('failed to save a new client', e);
        const serverErrorToUiAlertError = new Map<string, string>();
        serverErrorToUiAlertError.set('duplicate-client-name', 'This client name is already in use.');
        serverErrorToUiAlertError.set('duplicate-url', 'This URL is already in use.');
        const errorLookup: string | undefined = serverErrorToUiAlertError.get(e.error);
        this.error = errorLookup ? errorLookup : 'Failed to save the client.';
      } finally {
        this.saving = false;
      }
    },
    formatDate(dateStr: string) {
      return formatIsoDateToMmDdYyyy(dateStr, "n/a");
    },
    dateUpdated(dateUpdated: string) {
      if (dateUpdated === 'start') {
        this.startDateMenu = false
      } else if (dateUpdated === 'end') {
        this.endDateMenu = false
      }
    },
  }
});
</script>

<style scoped>

</style>
