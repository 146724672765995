<template>
  <div>
    <SnackbarMessage v-model="showError" error>{{ this.error }}</SnackbarMessage>
    <SnackbarMessage v-model="showDeleteMsg" data-test="sft-delete-success-message">SFT successfully deleted</SnackbarMessage>

    <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="15"
        :search="search"
        align="start"
        :loading="loadingAllFiles"
        dense
        sort-by="createdAt"
        sort-desc
        must-sort
        class="elevation-1"
        :footer-props="{itemsPerPageOptions: [10, 15, 25, -1] }"
        :mobile-breakpoint="800"
    >

      <template v-slot:[`item.createdAt`]="{item}">
        {{formatIsoDateToMmDdYyyy(item.createdAt)}}
      </template>

      <template v-slot:[`item.from`]="{item}">
        <template v-if="item.routingType === toClient">
          <span class="cybeta-text">{{ getFrom(item) }}</span>
        </template>
        <template v-else>
          {{ getFrom(item) }}
        </template>
      </template>
      <template v-slot:[`item.to`]="{item}">
        <template v-if="item.routingType === toClient">
          {{ getTo(item) }}
        </template>
        <template v-else>
          <span class="cybeta-text">{{ getTo(item) }}</span>
        </template>
      </template>
      <template v-slot:[`item.description`]="{item}">
        <v-tooltip bottom v-if="isLargeDescription(item.description)">
          <template v-slot:activator="{on}">
            <span v-on="on">{{ getDisplayDescription(item.description) }}</span>
          </template>
          <span>{{ item.description }}</span>
        </v-tooltip>
        <span v-else>{{ getDisplayDescription(item.description) }}</span>
      </template>

      <template v-slot:[`item.filename`]="{item}">
        <div class="d-flex align-center" data-test="filename-cell">
          <template v-if="!item.isRead">
            <v-tooltip bottom>
              <template v-slot:[`activator`]="{ on, attrs }">
                <v-icon color="primary" v-bind="attrs" v-on="on" data-test="unread-ind">mdi-circle-small</v-icon>
              </template>
              <span>Unread by {{getTo(item)}}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <v-tooltip bottom>
              <template v-slot:[`activator`]="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="secondary" class="mr-2" small>mdi-check</v-icon>
              </template>
              <span>Downloaded on {{formatIsoDateToMmDdYyyy(item.readAt)}}</span>
            </v-tooltip>
          </template>
          <div :class="item.isRead ? '' : 'font-weight-bold'">
            <DownloadLink :name="item.filename" :id="item.id" file-type="sft" @marked-read="$emit('marked-read', item)"/>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{isMobile, item}">
        <v-btn
            :class="isMobile ? 'mb-5': ''"
            :icon="!isMobile"
            :small="!isMobile"
            :color="isMobile ? 'default': 'primary'"
            @click="deleteItem(item)"
            data-test="delete-btn"
        >
          <template v-if="isMobile">
            <v-icon left>mdi-delete</v-icon> Delete
          </template>
          <template v-else>
            <v-icon small>mdi-delete</v-icon>
          </template>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="showDeleteConfirmation" persistent max-width="250">
      <v-card>
        <v-card-title class="text-h5">
          Confirm Delete
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete the SFT <b>{{ deleteSftItem ? deleteSftItem.filename : '' }}</b>?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              type="button" :disabled="deletingSft" data-test="cancel-delete-sft-btn"
              @click="showDeleteConfirmation = false"
          >
            Cancel
          </v-btn>
          <v-btn
              color="primary" type="button" :loading="deletingSft" data-test="delete-sft-btn"
              @click="deleteSft()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue, {PropType} from "vue";
import {RoutingType, SecureFileTransfer} from "@/model/SecureFileTransfer";
import SnackbarMessage from "@/components/SnackbarMessage.vue";
import {formatIsoDateToMmDdYyyy} from "@/services/view-helpers";
import DownloadLink from "@/components/DownloadLink.vue";
import secureFileTransferService from "@/services/SecureFileTransferService";

export default Vue.extend({
  name: "SecureFileTransferList",
  components: {
    DownloadLink,
    SnackbarMessage
  },
  props: {
    search: String,
    loadingAllFiles: Boolean,
    secureFileTransfers: Array as PropType<SecureFileTransfer[]>,
    clientId: Number
  },
  data() {
    return {
      error: '',
      showError: false,
      headers: [
        {text: 'From', value: 'from', class: 'text-no-wrap'},
        {text: 'To', value: 'to', class: 'text-no-wrap'},
        {text: 'Posted', value: 'createdAt', class: 'text-no-wrap'},
        {text: 'Filename', value: 'filename', class: 'text-no-wrap'},
        {text: 'Description', value: 'description', sortable: 'false', class: 'text-no-wrap'},
        {text: '', filterable: false, sortable: false, value: 'actions', width: '1px'},
      ],
      toClient: RoutingType.TO_CLIENT,
      fromClient: RoutingType.FROM_CLIENT,
      deleteSftItem: null as (SecureFileTransfer|null),
      showDeleteConfirmation: false,
      showDeleteMsg: false,
      deletingSft: false,
    }
  },
  computed: {
    items(): SecureFileTransfer[] {
      if (this.clientId) {
        return this.secureFileTransfers.filter(sft => sft.clientId === this.clientId);
      } else {
        return this.secureFileTransfers;
      }
    }
  },
  methods: {
    formatIsoDateToMmDdYyyy,
    getFrom(item: SecureFileTransfer): string {
      if (item.routingType === RoutingType.TO_CLIENT) {
        return "Cybeta";
      } else {
        return item.clientDisplayName ?? '';
      }
    },
    getTo(item: SecureFileTransfer): string {
      if (item.routingType === RoutingType.TO_CLIENT) {
        return item.clientDisplayName ?? '';
      } else {
        return "Cybeta";
      }
    },
    getDisplayDescription(description: string): string {
      if (this.isLargeDescription(description)) {
        return description.substring(0, 30) + '...';
      } else {
        return description;
      }
    },
    formatDate(dateStr: string) {
      return formatIsoDateToMmDdYyyy(dateStr);
    },
    async deleteSft() {
      try {
        this.deletingSft = true;
        await secureFileTransferService.deleteSft(this.deleteSftItem!.id);
        this.$emit('sftDeleted', this.deleteSftItem!.id);
        this.showError = false;
        this.showDeleteMsg = true;
      } catch (e) {
        this.error = 'Failed to delete the SFT. Reason: ' + e.error;
        console.error('failed to delete the SFT ', e);
        this.showError = true;
        this.showDeleteMsg = false;
      } finally {
        this.showDeleteConfirmation = false
        this.deletingSft = false;
      }
    },
    deleteItem(item: SecureFileTransfer) {
      this.deleteSftItem = item;
      this.showDeleteConfirmation = true
    },
    isLargeDescription(description: string): boolean {
      return description.length > 25
    },
  },
})

</script>

<style scoped>

.cybeta-text {
  color: slategrey;
}
</style>
