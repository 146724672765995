<template>
  <v-app>
    <Header/>
    <v-main>
      <div class="main">
        <v-navigation-drawer app fixed clipped v-if="user && !errorLoadingUserProfile && !requiredTosAcceptance" class="sidebar hidden-md-and-down">
          <NavMenu/>
        </v-navigation-drawer>
        <template v-if="!loadingUserProfile">
          <template v-if="errorLoadingUserProfile">
            <Page class="text-center">
              Failed to load user profile
            </Page>
          </template>
          <template v-else-if="userHasRoutePrivilege">
            <Page>
              <router-view/>
            </Page>
          </template>
          <template v-else>
            <Page class="text-center">
              Unauthorized
            </Page>
          </template>
        </template>
        <template v-else>
          <v-progress-linear indeterminate color="accent" data-test="app-progress"></v-progress-linear>
        </template>
      </div>
    </v-main>
    <Footer />
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {mapGetters, mapState} from "vuex";
import Page from "@/components/Page.vue";
import Privilege from "@/model/Privilege";
import AuthHelper from "@/services/AuthHelper";
import NavMenu from "@/components/NavMenu.vue";
import {noop} from "@/services/view-helpers";

export default Vue.extend({
  name: 'App',
  components: {NavMenu, Header, Footer, Page},
  computed: {
    ...mapState(['user', 'loadingUserProfile', 'userProfile', "errorLoadingUserProfile"]),
    ...mapGetters(['requiredTosAcceptance']),
    userHasRoutePrivilege(): boolean {
      const routePrivilegeReq = (this.$route.meta?.privilegeReq ?? []) as Privilege[];
      if (!this.user && routePrivilegeReq.length > 0) {
        return false;
      }
      return routePrivilegeReq.length === 0 ? true : this.can(this.userProfile, ...routePrivilegeReq);
    }
  },
  watch: {
    loadingUserProfile() {
      this.handleMissingPrivilege();
    },
    userHasRoutePrivilege() {
      this.handleMissingPrivilege();
    },
    requiredTosAcceptance() {
      this.checkTos();
    }
  },
  mounted() {
    this.checkTos();
  },
  methods: {
    ...AuthHelper,
    async handleMissingPrivilege() {
      if (!this.loadingUserProfile && !this.userHasRoutePrivilege) {
        console.error(`user does not have access to route: ${this.$route.name}`);
        await this.$router.replace('/').catch(noop);
      }
    },
    checkTos() {
      if (this.requiredTosAcceptance) {
        this.$store.commit('setOriginalRoute', this.$route);
        this.$router.replace('/tos').catch(noop);
      }
    }
  }
});
</script>


<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .02);
}

.main {
  height: 100%;
  display: flex;
}

.sidebar {
  border-right: 1px solid lightgray;
  width: 200px;
}

</style>
