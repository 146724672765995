<template>
  <div class="report-assignments d-flex justify-space-between" @click="startEditing" data-test="assignments">
    <div v-if="report.assignments && report.assignments.length > 0">
      <div class="assignment" v-for="user in report.assignments" :key="user.email">
        <span small data-test="assignment-name">{{user.firstName}} {{user.lastName}}</span>
      </div>
    </div>
    <div class="unassigned" v-else>unassigned</div>
    <div class="edit">
      <v-icon small color="primary">mdi-chevron-down</v-icon>
    </div>
    <v-dialog v-model="showForm" persistent max-width="500">
      <v-card>
        <v-alert type="error" v-if="error" dismissible rounded="0">{{error}}</v-alert>
        <v-card-title class="mb-3">Report assignment</v-card-title>
        <v-card-subtitle>{{report.title}}</v-card-subtitle>
        <v-card-text>
          <UserSelect placeholder="unassigned" emit-full-object :value="selectionValue" @input="setSelection" :client-id-filter="report.clientId" multiple dense/>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer/>
          <v-btn @click="cancel" :disabled="saving">Cancel</v-btn>
          <v-btn color="primary" :loading="saving" @click="save" data-test="save-assignments-btn">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue, {PropType} from "vue";
import IntelReport from "@/model/IntelReport";
import UserSelect from "@/components/UserSelect.vue";
import UserProfile from "@/model/UserProfile";
import reportService from "@/services/ReportService";

export default Vue.extend({
  name: "ReportAssignments",
  components: {UserSelect},
  props: {
    report: Object as PropType<IntelReport>,
  },
  data() {
    return {
      showForm: false,
      saving: false,
      error: '',
      selection: this.report.assignments ?? []
    }
  },
  computed: {
    selectionValue(): string[] {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.selection.map(u => u.cognitoId!);
    },
  },
  methods: {
    startEditing() {
      this.showForm = true;
      this.$emit('editing', true);
    },
    setSelection(userProfiles: UserProfile[]) {
      this.selection = userProfiles;
    },
    cancel() {
      this.selection = this.report.assignments ?? [];
      this.showForm = false;
      this.$emit('editing', false);
    },
    async save() {
      try {
        this.saving = true;
        await reportService.updateReportAssignments(this.report.id, this.selection.map(u => u.id));
        this.showForm = false;
        this.$emit('editing', false);
        this.$emit('saved', this.selection);
      } catch (e) {
        console.error('failed to save assignments:', e);
        this.error = 'There was a problem saving the assignment.';
      } finally {
        this.saving = false;
      }
    }
  }
})
</script>

<style scoped>
  .report-assignments {
    padding: 5px;
    position: relative;
    color: var(--v-primary-base);
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid lightgray;
  }
  .assignment {
    white-space: nowrap;
    padding: 0 10px 0 5px;
    margin-bottom: 2px;
  }
  .unassigned {
    font-style: italic;
    padding: 0 10px 0 5px;
  }
  .report-assignments:hover>.edit {
    display: inline;
  }
  .report-assignments:hover {
    border: 1px solid var(--v-primary-base);
    background: #e8eff2;
  }
  .assignment:last-child {
    border-bottom: none;
  }
</style>
