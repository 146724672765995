<template>
  <div>
    <div class="d-flex justify-space-between mb-2 align-baseline flex-wrap">
      <v-btn color="primary" outlined @click="editItem(null)" data-test="new-user-btn" class="mr-3">
        New client
      </v-btn>
      <div class="search-field">
        <v-text-field
            data-test="search-field"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            placeholder="search"
            clearable
        ></v-text-field>
      </div>
    </div>
    <v-data-table
        :headers="headers"
        :items="clients"
        :items-per-page="15"
        :search="search"
        align="start"
        :loading="loadingAllClients"
        dense
        :mobile-breakpoint="800"
        sort-by="name"
        must-sort
        class="elevation-1"
        :footer-props="{itemsPerPageOptions: [10, 15, 25, -1] }"
    >
      <template v-slot:[`item.actions`]="{ isMobile, item }">
        <v-menu left :offset-y="true">
          <template v-slot:[`activator`]="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                :class="isMobile ? 'mb-5' : ''"
                :icon="!isMobile"
                :small="!isMobile"
                :color="isMobile ? 'default' : 'primary'"
                data-test="client-options-btn"
            >
              <template v-if="isMobile">
                <v-icon>mdi-dots-horizontal</v-icon>
              </template>
              <template v-else>
                <v-icon small>mdi-dots-horizontal</v-icon>
              </template>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editItem(item)" data-test="update-client-info-btn">
              <v-list-item-icon class="mr-1"><v-icon :disabled="!item.enabled" small>mdi-pencil</v-icon></v-list-item-icon>
              <v-list-item-title>Update Client Info</v-list-item-title>
            </v-list-item>
            <v-list-item @click="showDeleteConfirmation(item)" data-test="delete-client-btn">
              <v-list-item-icon class="mr-1"><v-icon small>mdi-delete</v-icon></v-list-item-icon>
              <v-list-item-title>Delete Client</v-list-item-title>
            </v-list-item>
            <v-list-item @click="handleShowUsers(item)" data-test="show-users-btn">
              <v-list-item-icon class="mr-1"><v-icon small>mdi-account-multiple</v-icon></v-list-item-icon>
              <v-list-item-title>View users</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`top`]>
        <v-dialog v-model="showClientForm" max-width="1200">
          <ClientForm :client-to-edit="editedItem" @saved="handleClientSaved" @cancel="showClientForm = false"/>
        </v-dialog>
      </template>
      <template v-slot:[`item.contractStart`]="{item}">
        <div v-if="item.contractStart || item.contractEnd">
          <div class="text-no-wrap">
            {{ formatDate(item.contractStart) }}&mdash;
          </div>
          <div class="text-no-wrap">
            {{ formatDate(item.contractEnd) }}
          </div>
        </div>
      </template>
      <template v-slot:[`item.ndaStatus`]="{item}">
        {{ booleanToYesNo(item.ndaStatus) }}
      </template>
      <template v-slot:[`item.secureFileTransfer`]="{item}">
        {{ booleanToYesNo(item.secureFileTransfer) }}
      </template>
      <template v-slot:[`item.humanIntelReports`]="{item}">
        {{ booleanToYesNo(item.humanIntelReports) }}
      </template>
    </v-data-table>
    <v-dialog v-model="showDeleteDialog" max-width="500px" >
      <v-card>
        <v-alert v-if="error" type="error" class="ma-0" rounded="0" dismissible data-test="delete-client-error-alert">{{error}}</v-alert>
        <v-card-title class="text-h6">Are you sure?</v-card-title>
        <v-card-text>
          <p>You are about to permanently delete <strong>{{ deletedItemName }}</strong>, its users, and all reports. Type the client name to enable the delete button.</p>
          <v-text-field
              v-model="nameOfClientToDelete"
              label="Enter client name exactly"
              clearable
              dense
              outlined
              data-test="delete-client-name-entry"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showDeleteDialog = false">Cancel</v-btn>
          <v-btn color="error" @click="handleClientDeleted" :disabled="!matchedClientToDelete" :loading="saving" data-test="delete-client-confirm-btn">Delete Client</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDeleteFailuresDialog" max-width="500px">
      <v-card>
        <v-card-title class="mb-2">Deletion Failures</v-card-title>
        <v-alert type="error" class="mx-4" data-test="deletion-errors-alert">Client <strong>{{ lastDeletedClientName }}</strong> was deleted. However,
          there were failures deleting the following associated items:
        </v-alert>
        <v-card-text>
          <ul>
            <li v-if="deleteFailures.intelReports" class="mb-2" data-test="report-deletion-error">Intel Reports were not deleted from S3 and folder
              {{ `${deleteFailures.clientUuid}/` }} must be manually deleted. Be sure to copy this folder ID so you can
              locate the folder to delete!
            </li>
            <li v-if="deleteFailures.secureFileTransfers" class="mb-2" data-test="sft-deletion-error">Secure File Transfers were not deleted from S3
              and folder {{ `${deleteFailures.clientUuid}/` }} must be manually deleted. Be sure to copy this folder ID
              so you can locate the folder to delete!
            </li>
            <template v-for="user in deleteFailures.users">
              <li :key="user.cognitoId" class="mb-2" data-test="user-deletion-error">User {{ `${user.email}` }} was not deleted from Cognito and must be
                manually deleted
              </li>
            </template>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="handleCloseDeleteFailuresDialog" data-test="close-deletion-error-btn">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ClientUserListDialog v-model="showUsers" :client="showUsersClient"/>
    <SnackbarMessage v-model="showSaveSuccess" data-test="save-success-message">"{{ lastSavedClientName }}" SAVED
      SUCCESSFULLY!
    </SnackbarMessage>
    <SnackbarMessage v-model="showDeleteSuccess" data-test="delete-success-message">"{{ lastDeletedClientName }}" DELETED
      SUCCESSFULLY!
    </SnackbarMessage>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import clientService from "@/services/ClientService";
import {Client, ClientForSave} from "@/model/Client";
import {mapState} from "vuex";
import SnackbarMessage from "@/components/SnackbarMessage.vue";
import {formatIsoDateToMmDdYyyy} from "@/services/view-helpers";
import ClientForm from "@/components/ClientForm.vue";
import UserProfile from "@/model/UserProfile";
import ClientUserListDialog from "@/components/ClientUserListDialog.vue";

export default Vue.extend({
  name: "AdminClients",
  components: {ClientUserListDialog, ClientForm, SnackbarMessage},
  data() {
    return {
      loadingAllClients: false,
      error: '',
      search: '',
      showClientForm: false,
      headers: [
        {text: '', filterable: false, sortable: false, value: 'actions', width: '1px'},
        {text: 'Num', align: 'start', value: 'displayNum', class: 'text-no-wrap'},
        {text: 'Client', align: 'start', value: 'name', class: 'text-no-wrap'},
        {text: 'Display Name', align: 'start', value: 'displayName', class: 'text-no-wrap'},
        {text: 'Type', value: 'clientType', class: 'text-no-wrap'},
        {text: 'Domain', value: 'topLevelDomain', class: 'text-no-wrap'},
        {text: 'NDA', filterable: false, value: 'ndaStatus', class: 'text-no-wrap'},
        {text: 'Contract', filterable: false, value: 'contractStart', class: 'text-no-wrap'},
        {text: 'Analysis', value: 'automatedAnalysis', class: 'text-no-wrap'},
        {text: 'SFT', filterable: false, value: 'secureFileTransfer', class: 'text-no-wrap'},
        {text: 'OverWatch', filterable: false, value: 'humanIntelReports', class: 'text-no-wrap'},
        {text: 'Parent', value: 'parentClientName', class: 'text-no-wrap'},
      ],
      clients: Array<Client>(),
      editedItem: null as (null|ClientForSave),
      deletedItem: null as (null|Client),
      showDeleteDialog: false,
      nameOfClientToDelete: '',
      lastSavedClientName: '',
      lastDeletedClientName: '',
      showSaveSuccess: false,
      showDeleteSuccess: false,
      showDeleteFailuresDialog: false,
      deleteFailures: {
        clientUuid: '',
        intelReports: false,
        secureFileTransfers: false,
        users: [] as UserProfile[]
      },
      saving: false,
      showUsers: false,
      showUsersClient: null as Client|null
    }
  },
  computed: {
    ...mapState(['user']),
    deletedItemName(): string {
      if (this.deletedItem) {
        return this.deletedItem.name
      } else {
        return 'CLIENT';
      }
    },
    matchedClientToDelete(): boolean {
      const caseAndWhitespaceInsensitiveClientName = this.nameOfClientToDelete.split(' ').join('').toLocaleLowerCase();
      const caseAndWhitespaceInsensitiveClientToDelete = this.deletedItem?.name.split(' ').join('').toLocaleLowerCase();
      return caseAndWhitespaceInsensitiveClientName === caseAndWhitespaceInsensitiveClientToDelete;
    },
  },
  methods: {
    formatDate(dateStr: string) {
      return formatIsoDateToMmDdYyyy(dateStr, "n/a");
    },
    booleanToYesNo(booleanValue: boolean | null) {
      if (booleanValue === null) {
        return null;
      }
      return booleanValue ? 'Yes' : 'No';
    },
    editItem(item: Client|null) {
      this.editedItem = item;
      this.showClientForm = true
    },
    showDeleteConfirmation(item: Client|null) {
      this.deletedItem = item;
      this.showDeleteDialog = true;
    },
    handleShowUsers(client: Client) {
      this.showUsersClient = client;
      this.showUsers = true;
    },
    handleClientSaved(client: Client) {
      this.showClientForm = false
      const editedIndex = this.clients.findIndex(c => c.id === client.id);
      if (editedIndex > -1) {
        Object.assign(this.clients[editedIndex], client);
      } else {
        this.clients.push(client);
      }
      this.lastSavedClientName = client.name;
      this.showSaveSuccess = true;
    },
    async handleClientDeleted() {
      this.error = '';
      this.saving = true;
      try {
        if (this.deletedItem?.id) {
        const result = await clientService.deleteClient(this.deletedItem?.id);
        this.lastDeletedClientName = this.deletedItemName;
        await this.callApiGetAllClients();
        this.showDeleteDialog = false;
        this.showDeleteSuccess = true;
        this.deletedItem = null;
        this.nameOfClientToDelete = '';
        if (typeof result === 'object') {
          if (result.reportFilesDeletionFailed || result.sftFilesDeletionFailed || result.userPoolDeletionFailures?.length > 0) {
            this.deleteFailures.clientUuid = result.clientUuid;
            this.deleteFailures.intelReports = result.reportFilesDeletionFailed;
            this.deleteFailures.secureFileTransfers = result.sftFilesDeletionFailed;
            this.deleteFailures.users = result.userPoolDeletionFailures;
            this.showDeleteFailuresDialog = true;
            }
          }
        } else {
          console.error('could locate client id')
          this.error = 'could not locate the client id';
        }
      } catch (e) {
        console.error('failed to delete client', e);
        this.error = 'failed to delete the client';
      } finally {
        this.saving = false;
      }
    },
    handleCloseDeleteFailuresDialog() {
      this.showDeleteFailuresDialog = false;
      this.deleteFailures = {
        clientUuid: '',
        intelReports: false,
        secureFileTransfers: false,
        users: []
      }
    },
    async callApiGetAllClients() {
      try {
        this.loadingAllClients = true;
        this.clients = await clientService.apiGetAllClients();
        this.error = '';
      } catch (e) {
        console.error('failed to lookup the clients', e);
        this.error = e.message;
      } finally {
        this.loadingAllClients = false;
      }
    },
  },
  created() {
    // Lookup doing this in created() vs mounted()
    this.callApiGetAllClients();
  },
});
</script>

<style scoped>

</style>

