enum ReportType {
    INTEL = 'intel',
    SUMMARY = 'summary',
    BROADCAST = 'broadcast',
}

export const reportTypeOptions = [
    {value: ReportType.INTEL, text: 'INTEL'},
    {value: ReportType.SUMMARY, text: 'SUMMARY'},
    {value: ReportType.BROADCAST, text: 'BROADCAST'},
]

export default ReportType;
