import {ThemeOptions} from "vuetify/types/services/theme";

const theme: ThemeOptions = {
  themes: {
    light: {
      primary: "#015174",
      secondary: "#54c6af",
      accent: "#bda13b",
      error: "#FF5252",
      info: "#27badd",
      success: "#54c6af",
      warning: "#bda13b",
    }
  },
  options: {
    customProperties:true
  }
}

export default theme;
