<template>
  <v-app-bar app fixed clipped-left elevation="0" color="primary" dark :extended="false">

    <template v-if="user && !errorLoadingUserProfile && !requiredTosAcceptance">
      <v-app-bar-nav-icon class="hidden-lg-and-up secondary--text" @click="nav = true"></v-app-bar-nav-icon>

      <v-navigation-drawer
          light
          v-model="nav"
          temporary
          absolute
          app
      >
        <NavMenu/>
      </v-navigation-drawer>
    </template>

    <v-toolbar-title class="text-h5">Cybeta INTELink</v-toolbar-title>

    <template v-if="user">
      <v-spacer></v-spacer>
      <v-menu bottom left :offset-y="true">
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab small color="secondary" v-bind="attrs" v-on="on" data-test="user-menu">{{initials}}</v-btn>
        </template>

        <v-list>
          <v-list-item to="/profile" v-if="!requiredTosAcceptance">
            <v-list-item-title>Profile settings</v-list-item-title>
          </v-list-item>
          <v-divider/>
          <v-list-item @click="logout" data-test="sign-out-btn">
            <v-list-item-title>Sign out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>

</template>

<script lang="ts">
import Vue from "vue";
import {mapState} from "vuex";
import AuthHelper from '@/services/AuthHelper'
import NavMenu from "@/components/NavMenu.vue";

export default Vue.extend({
  name: "Header",
  components: {NavMenu},
  data() {
    return {
      nav: null as (null|boolean)
    }
  },
  computed: {
    ...mapState(['user', 'userProfile', 'errorLoadingUserProfile']),
    initials(): string {
      return `${this.user.firstName[0].toUpperCase()}${this.user.lastName[0].toUpperCase()}`
    },
    adminLink(): string {
      if (this.$route.name === 'AdminUsers') {
        return '/admin/users';
      } else if (this.$route.name === 'AdminClients') {
        return '/admin/clients';
      } else {
        return this.canAdminClients(this.userProfile) ? '/admin/clients' : '/admin/users';
      }
    },
    requiredTosAcceptance(): boolean {
      return this.userProfile?.requiredTosAcceptance;
    }
  },
  methods: {
    ...AuthHelper,
    async logout() {
      await this.$store.dispatch('logoutUser');
      if (this.$route.name !== 'Login') {
        await this.$router.push({name: 'Login'});
      }
    },
  }
})
</script>

<style scoped>
</style>
