<template>
  <v-form ref="form" @submit.prevent="submit" v-model="valid" lazy-validation>
    <v-alert type="error" v-if="error" dismissible>{{error}}</v-alert>
    <h3>SMS verification</h3>
    <p class="mt-2 font-weight-light">Please enter the verification code that has been sent to
      <span class="font-weight-bold" v-if="number">{{number}}</span>
      <span v-else>the phone number associated to your profile.</span>
    </p>
    <v-text-field dense outlined style="width: 150px;" v-model="code" :rules="rules" label="Code" type="number" autofocus/>
    <div class="alternate mt-3">
      <span class="font-weight-light mr-1">Didn't receive the text?</span>
      <a @click="handleBackToLogin">Try signing in again</a>
    </div>
    <div class="alternate mt-3">
      <span class="font-weight-light mr-1">If the problem persists contact an administrator to confirm the phone number associated to your profile.</span>
    </div>
    <div class="mt-5">
      <v-btn color="primary" type="submit" :loading="submitting">Verify</v-btn>
    </div>
  </v-form>
</template>

<script lang="ts">
import Vue, {PropType} from "vue";
import FormElement from "@/model/FormElement";
import {AuthResponse, AuthStep} from "@/model/AuthTypes";
import {Auth} from "aws-amplify";

export default Vue.extend({
  name: "MfaForm",
  props: {
    currentAuthRes: Object as PropType<AuthResponse>
  },
  data() {
    return {
      valid: true,
      submitting: false,
      error: '',

      code: '',

      rules: [(v:string) => !!v || 'This field is required'],
    }
  },
  computed: {
    formElement(): FormElement {
      return this.$refs.form as unknown as FormElement;
    },
    number(): string {
      return this.currentAuthRes.challengeParam?.CODE_DELIVERY_DESTINATION ?? '';
    }
  },
  methods: {
    handleBackToLogin() {
      this.$emit('change', AuthStep.LOGIN);
    },
    validate () {
      return this.formElement.validate();
    },
    async submit() {
      this.error = '';

      if (!this.validate()) {
        return;
      }

      try {
        this.submitting = true;
        const result = await Auth.confirmSignIn(this.currentAuthRes, this.code);
        this.$emit('auth-response', result);
      } catch (e) {
        if (e.code === 'CodeMismatchException') {
          this.error = 'Invalid verification code. Please try again.';
        } else if (e.message.match(/session is expired/i)) {
            this.$emit('session-expired');
        } else {
          this.error = e.message;
        }
        console.error('failure confirming sign in', e);
      } finally {
        this.submitting = false;
      }
    },
  }
});
</script>

<style scoped>
  .alternate {
    font-size: .8em;
  }
</style>
