import Service from "@/services/Service";

class TosService extends Service {
    constructor() {
        super();
    }

    public async getByName(name: string): Promise<Blob> {
        const res = await this.axiosInstance.get(name, {responseType: 'arraybuffer', baseURL: '/'});
        if (!res.headers['content-type'].match(/application\/pdf/)) {
            console.log('could not load valid TOS');
            throw new Error('could not load valid TOS');
        }

        return new Blob([res.data]);
    }
}

const tosService = new TosService();
export default tosService;
