<template>
  <Page dense>
    <div class="d-flex justify-center tos">
      <v-alert v-if="errorLoading" type="error" rounded="0">{{errorLoading}}</v-alert>
      <v-card max-width="800" v-else>
        <v-alert v-if="errorAccepting" type="error" dismissible rounded="0">{{errorAccepting}}</v-alert>
        <v-card-title>Action required</v-card-title>
        <v-divider/>
        <v-progress-linear v-if="loading" indeterminate color="accent"/>
        <div class="pa-5">
          <div class="text-content">
            Please review and accept the latest <a :href="tosLink" target="_blank" data-test="tos-link" @click="handleTosClick">terms of service</a> to continue.
          </div>
          <div class="mb-4">
            <v-checkbox v-model="accepted" :disabled="!shownOnce" label="I agree to the INTELink terms of service" hint="View the terms of service before agreeing" :persistent-hint="!shownOnce" color="secondary"/>
          </div>
          <v-btn color="primary" :disabled="!accepted" :loading="accepting" @click="acceptTos" data-test="cont-btn">Continue</v-btn>
        </div>
      </v-card>
    </div>
  </Page>
</template>

<script lang="ts">
import Vue from "vue";
import Page from "@/components/Page.vue";
import tosService from "@/services/TosService";
import userService from "@/services/UserService";
import {noop} from "@/services/view-helpers";
import {mapGetters} from "vuex";

const TOS_VIEWED_KEY = 'tosViewed';

export default Vue.extend({
  name: "TermsOfService",
  components: {Page},
  data() {
    return {
      show: false,
      shownOnce: false,
      accepted: false,
      errorAccepting: '',
      errorLoading: '',
      loading: true,
      accepting: false,
    }
  },
  computed: {
    ...mapGetters(['requiredTosAcceptance']),
    tosLink(): string {
      return `/${this.requiredTosAcceptance}`;
    }
  },
  mounted() {
    if (!this.requiredTosAcceptance) {
      this.$router.replace('/').catch(noop);
    } else {
      this.verifyPdf();
    }
    this.shownOnce = localStorage.getItem(TOS_VIEWED_KEY) === this.requiredTosAcceptance;
  },
  methods: {
    async verifyPdf() {
      // Use axios to get the PDF to make sure it resolves correctly.. even though using a direct link in the iframe.
      try {
        this.errorLoading = '';
        this.loading = true;
        await tosService.getByName(this.requiredTosAcceptance);
      } catch (e) {
        console.log('failed to get the terms of service: ', e);
        this.errorLoading = 'There was an error loading the application. Please try again later.';
      } finally {
        this.loading = false;
      }
    },
    handleTosClick() {
      this.shownOnce = true;
      localStorage.setItem(TOS_VIEWED_KEY, this.requiredTosAcceptance);
    },
    async acceptTos() {
      try {
        this.errorAccepting = '';
        this.accepting = true;
        await userService.acceptTosForCurrentUser();
        await this.$store.dispatch('loadUserProfile').catch(noop);
        await this.$router.push(this.$store.state.originalRoute ?? '/').catch(noop);
        this.$store.commit('setOriginalRoute', null);
      } catch (e) {
        console.error('failed saving TOS acceptance: ', e);
        this.errorAccepting = 'There was an error processing the request';
      } finally {
        this.accepting = false;
      }
    }
  }
});
</script>

<style scoped>
  .text-content {
    font-size: 1.2em;
  }
  a span {
    text-decoration: underline;
  }
</style>
