<template>
  <div class="new-sft-form">
    <v-card>
      <v-progress-linear :value="progress" color="accent" v-if="saving"/>
      <v-alert v-if="error" type="error" rounded="0" dismissible data-test="error-alert">{{error}}</v-alert>
      <v-card-title>
        <span class="text-h5 mb-3">New Secure File Transfer</span>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="save" ref="form" v-model="valid" lazy-validation>
          <div class="routing">

            <!-- header -->
            <div class="overline">FROM</div>
            <div>&nbsp;</div>
            <div class="overline">TO</div>

            <!-- body -->
            <div data-test="sft-from">
              <template v-if="isToClient"><span class="internal">Cybeta</span></template>
              <template v-else>
                <ClientSelect v-model="clientId" dense label="" placeholder="Select client" :rules="clientRules" :disable-func="c => !c.secureFileTransfer"/>
              </template>
            </div>
            <div class="text-center"><v-icon class="direction-icon accent--text">mdi-arrow-right-bold</v-icon></div>
            <div data-test="sft-to">
              <template v-if="isToClient">
                <ClientSelect v-model="clientId" dense label="" placeholder="Select client" :rules="clientRules" :disable-func="c => !c.secureFileTransfer"/>
              </template>
              <template v-else><span class="internal">Cybeta</span></template>
            </div>

          </div>
          <v-file-input class="mb-5" v-model="files" outlined prepend-inner-icon="mdi-paperclip" dense prepend-icon="" label="File" :rules="fileRules" multiple counter show-size hint="Select one or more files" persistent-hint>
            <template v-slot:selection="{ text }">
              <v-chip small label color="secondary">{{text}}</v-chip>
            </template>
          </v-file-input>

          <v-text-field v-model="description" outlined dense label="Description" :rules="descriptionRules" data-test="description-input" />
          <v-card-actions class="d-flex justify-end mt-5">
            <v-btn large @click="cancel" type="button" :disabled="saving">Cancel</v-btn>
            <v-btn large color="primary" type="submit" :loading="saving">Create</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue, {PropType} from "vue";
import {mapState} from "vuex";
import ClientSelect from "@/components/ClientSelect.vue";
import FormElement from "@/model/FormElement";
import {RoutingType, SecureFileTransferForCreate} from "@/model/SecureFileTransfer";
import secureFileTransferService from "@/services/SecureFileTransferService";
import { AxiosProgressEvent } from "axios";

export default Vue.extend({
  name: "NewSftForm",
  components: {ClientSelect},
  props: {
    routingType: String as PropType<RoutingType>,
  },
  data() {
    return {
      uploadedProgress: {} as {[key: string]: number},
      saving: false,
      valid: true,
      error: '',

      descriptionRules: [(v:string) => !!v || 'Description is required'],
      clientRules: [(v:string) => !!v || 'Client is required'],
      fileRules: [(v:string) => !!v || 'File is required'],

      clientId: null,
      files: null as (null|FileList),
      description: '',
    }
  },
  computed: {
    ...mapState(['userProfile']),
    formElement(): FormElement {
      return this.$refs.form as unknown as FormElement;
    },
    isToClient(): boolean {
      return this.routingType === RoutingType.TO_CLIENT;
    },
    sftCreateRequest(): SecureFileTransferForCreate {
      const filenames = new Array<string>();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      for (const file of this.files!) {
        filenames.push(file.name);
      }
      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        clientId: this.clientId!,
        description: this.description,
        filenames,
        routingType: this.routingType
      }
    },
    totalFileSize(): number {
      if (!this.files) return 0;

      let total = 0;
      for (const file of this.files) {
        total += file.size;
      }
      return total;
    },
    progress(): number {
      const current = Object.values(this.uploadedProgress).reduce((tot, amt) => tot + amt, 0);
      return 100 * (current / this.totalFileSize);
    }
  },
  mounted() {
    this.defaultClient();
  },
  watch: {
    routingType(newVal: RoutingType) {
      if (newVal === RoutingType.FROM_CLIENT) {
        this.defaultClient();
      } else {
        this.clientId = null;
      }
    }
  },
  methods: {
    reset() {
      this.formElement.reset();
      this.defaultClient();
      this.files = null;
      this.description = '';
    },
    validate (): boolean {
      return this.formElement.validate();
    },
    defaultClient() {
      if (this.isToClient) {
        this.clientId = null;
      } else {
        this.clientId = this.userProfile?.clientId ?? null;
      }
    },
    async save() {
      if (!this.validate()) {
        return;
      }

      try {
        this.saving = true;
        const results = await secureFileTransferService.createSft(this.sftCreateRequest);
        this.uploadedProgress = results.reduce((p, r) => ({...p, [r.secureFileTransfer.filename]: 0}), {});
        try {
          await Promise.all(results.map(result => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const file = this.fileForName(result.secureFileTransfer.filename)!
            return secureFileTransferService.uploadFile(result.signedPutUrl, file, this.uploadProgressTracker(result.secureFileTransfer.filename))
                .then(() => secureFileTransferService.setUploadVerified(result.secureFileTransfer.id));
          }));
        } catch (e) {
          console.error('failed to upload and verify one or more secure file transfers', e);
        }
        this.$emit('created', results.map(r => r.secureFileTransfer));
        this.reset();
      } catch (e) {
        this.error = 'Failed to create the secure file transfer';
        console.error('failed to create sft', e);
      } finally {
        this.saving = false;
      }
    },
    cancel() {
      this.$emit('cancel');
      this.reset();
    },
    uploadProgressTracker(filename: string) {
      return (progressEvent: AxiosProgressEvent) => {
        this.uploadedProgress[filename] = progressEvent.loaded;
      }
    },
    fileForName(name: string): (File|undefined) {
      if (!this.files) return undefined;

      for (const file of this.files) {
        if (file.name === name) {
          return file;
        }
      }
    }
  }
});</script>

<style scoped>
  .routing {
    display: grid;
    grid-template-columns: .5fr 50px .5fr;
    grid-template-rows: auto auto;
    grid-column-gap: 20px;
    margin-bottom: 20px;
  }
  .direction-icon {
    position: relative;
    bottom: -5px;
    font-size: 30px;
  }
  .internal {
    display: inline-block;
    border: 1px solid lightgray;
    color: gray;
    border-radius: 5px;
    padding: 7px;
    width: 100%;
  }
</style>
