import Service from "@/services/Service";
import Group from "@/model/Group";

class GroupService extends Service {
    constructor() {
        super();
    }

    public async list(): Promise<Group[]> {
        const res = await this.axiosInstance.get<Group[]>('/groups')
        return res.data;
    }
}

const groupService = new GroupService();
export default groupService;
