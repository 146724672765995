import Service from "@/services/Service";
import Privilege from "@/model/Privilege";
import User from "@/model/User";
import UserProfile, {UserProfileForEdit} from "@/model/UserProfile";
import {ProfileNotificationPrefs} from "@/model/NotificationPrefs";
import {AxiosResponse} from "axios";
import Role from "@/model/Role";

export interface UserPage {
    users: User[],
    pageToken?: string
}

class UserService extends Service {
    constructor() {
        super();
    }

    public async currentUserPrivileges(): Promise<Privilege[]> {
        const res = await this.axiosInstance.get<Privilege[]>('/users/current/privileges')
        return res.data;
    }

    public async currentUserProfile(): Promise<UserProfile> {
        const res = await this.axiosInstance.get<UserProfile>('/users/current/profile')
        return res.data;
    }

    public async acceptTosForCurrentUser(): Promise<void> {
        await this.axiosInstance.post('/users/current/tos-acceptance');
    }

    public async updateCurrentUserProfile(profile: UserProfileForEdit): Promise<void> {
        await this.axiosInstance.put<UserProfileForEdit>('/users/current/profile', profile);
    }

    public async createUserProfile(user: Partial<UserProfile>): Promise<UserProfile> {
        return (await this.axiosInstance.post<Partial<UserProfile>, AxiosResponse<UserProfile>>('/users', user)).data;
    }

    public async updateUserProfile(id: number, user: Partial<UserProfile>): Promise<UserProfile> {
        return (await this.axiosInstance.put<Partial<UserProfile>, AxiosResponse<UserProfile>>(`/users/${id}`, user)).data;
    }

    public async listUsers(lastNameFilter='', limit?: number, pageToken?: string): Promise<UserPage> {
        let url = `/users?lastNameFilter=${lastNameFilter}`;
        if (limit) {
            url += `&limit=${limit}`;
        }
        if (pageToken) {
            url += `&pageToken=${encodeURIComponent(pageToken)}`;
        }
        const res = await this.axiosInstance.get<UserPage>(url);
        return res.data
    }


    public async listInvitesOnly(limit?: number, pageToken?: string): Promise<UserPage> {
        let url = `/users?invitesOnly=true`;
        if (limit) {
            url += `&limit=${limit}`;
        }
        if (pageToken) {
            url += `&pageToken=${encodeURIComponent(pageToken)}`;
        }
        const res = await this.axiosInstance.get<UserPage>(url);
        return res.data
    }

    public async getFullUserProfile(id: number): Promise<UserProfile> {
        const res = await this.axiosInstance.get<UserProfile>(`/users/${id}`);
        return res.data;
    }

    public async currentUserNotificationPrefs(): Promise<ProfileNotificationPrefs> {
        const res = await this.axiosInstance.get<ProfileNotificationPrefs>('/users/current/notification-prefs');
        return res.data;
    }

    public async updateCurrentUserNotificationPrefs(prefs: ProfileNotificationPrefs): Promise<void> {
        await this.axiosInstance.put('/users/current/notification-prefs', prefs);
    }

    public async resendUserInviteEmail(id: number): Promise<number> {
        const res = await this.axiosInstance.put(`/users/current/resend-invite-email/${id}`);
        return res.status
    }

    public async getUserProfiles(roles?: Role[], clientId?: number): Promise<UserProfile[]> {
        const paramPairs = roles ? roles.map(r => ["roles", r]) : [];
        if (clientId) {
            paramPairs.push(['clientId', clientId.toString()]);
        }
        const params = new URLSearchParams(paramPairs)
        const res = await this.axiosInstance.get<UserProfile[]>(`/users/profiles`, { params });
        return res.data;
    }

    public async enableUser(id: number): Promise<void> {
        await this.axiosInstance.put(`/users/${id}/enable`);
    }

    public async disableUser(id: number): Promise<void> {
        await this.axiosInstance.put(`/users/${id}/disable`);
    }

    public async deleteUser(id: number): Promise<void> {
        await this.axiosInstance.delete(`/users/${id}`);
    }
}

const userService = new UserService();
export default userService;
