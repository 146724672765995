<template>
  <div class="user-list">
    <v-alert v-if="error" type="error">{{error}}</v-alert>
    <v-data-table
        :loading="loadingUsers"
        :headers="headers"
        :items="users"
        :items-per-page="5"
        class="elevation-1"
        sort-by="lastName"
        disable-sort
        disable-pagination
        hide-default-footer
        :mobile-breakpoint="850"
        :item-class="i => i.enabled ? '' : 'disabled-user'"
    >
      <template v-slot:[`header.name`]>
        Name <v-icon small>mdi-arrow-up</v-icon>
      </template>

      <template v-slot:[`item.name`]="{item}">
        <div data-test="user-name">
          {{fullName(item)}} <span v-if="!item.enabled">(Disabled)</span>
        </div>
      </template>

      <template v-slot:[`item.clientDisplayName`]="{item}">
        <span v-if="item.userProfile">
          {{item.userProfile.clientDisplayName}}
        </span>
      </template>

      <template v-slot:[`item.roleName`]="{item}">
        <span v-if="item.userProfile">
          {{roleDisplayName(item.userProfile.role)}}
        </span>
      </template>

      <template v-slot:[`item.actions`]="{ isMobile, item }">
        <v-progress-circular v-if="item.userProfile && processingProfileIds.includes(item.userProfile.id)" indeterminate :size="18" :width="2" color="accent"/>
        <v-menu v-else right :offset-y="true">
          <template v-slot:[`activator`]="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                :class="isMobile ? 'mb-5' : ''"
                :icon="!isMobile"
                :small="!isMobile"
                :color="isMobile ? 'default' : 'primary'"
                :disabled="!item.userProfile"
                data-test="user-options-btn"
            >
              <template v-if="isMobile">
                <v-icon>mdi-dots-horizontal</v-icon>
              </template>
              <template v-else>
                <v-icon small>mdi-dots-horizontal</v-icon>
              </template>
            </v-btn>
          </template>

          <v-list>
            <v-list-item :disabled="!item.enabled" @click="showEditForm(item)" data-test="update-user-info-btn">
              <v-list-item-icon class="mr-1"><v-icon :disabled="!item.enabled" small>mdi-pencil</v-icon></v-list-item-icon>
              <v-list-item-title>Update User Info</v-list-item-title>
            </v-list-item>
            <v-list-item
                @click="resendEmail(item)"
                :disabled="!hasNotLoggedInForFirstTime(item) || !item.enabled"
                data-test="resend-email-btn"
            >
              <v-list-item-icon class="mr-1"><v-icon :disabled="!hasNotLoggedInForFirstTime(item) || !item.enabled" small>mdi-email</v-icon></v-list-item-icon>
              <v-list-item-title>Resend Invite Email</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.enabled" data-test="disable-user-btn" @click="disableUser(item)">
              <v-list-item-icon class="mr-1"><v-icon small>mdi-cancel</v-icon></v-list-item-icon>
              <v-list-item-title>Disable user</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!item.enabled" data-test="enable-user-btn" @click="enableUser(item)">
              <v-list-item-icon class="mr-1"><v-icon small>mdi-check</v-icon></v-list-item-icon>
              <v-list-item-title>Enable user</v-list-item-title>
            </v-list-item>
            <v-list-item data-test="delete-user-btn" @click="confirmDeleteUser(item)">
              <v-list-item-icon class="mr-1"><v-icon small color="error">mdi-delete</v-icon></v-list-item-icon>
              <v-list-item-title class="error--text">Delete user</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:footer>
        <div class="pa-3 d-flex justify-end">
          <v-btn icon class="mr-3" @click="prevPage" :disabled="!hasPrevPage" data-test="prev-btn">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn icon @click="nextPage" :disabled="!hasNextPage" data-test="next-btn">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="showUserForm" max-width="800" persistent>
      <UserForm
        @cancel="showUserForm = false"
        @saved="userSaved"
        :user-to-edit-id="userToEditId"
      />
    </v-dialog>

    <v-dialog v-model="showDeleteConfirmation" persistent max-width="500">
      <v-card>
        <v-alert v-if="deleteError" type="error" rounded="0" dismissible data-test="error-alert">{{deleteError}}</v-alert>
        <v-card-title class="text-h5">
          Confirm Delete
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete the user: <span class="font-weight-bold" v-if="userToDelete">{{userToDelete.email}}</span>?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="ma-3">
            <v-btn class="mr-3" :disabled="deletingUser" @click="showDeleteConfirmation = false">Cancel</v-btn>
            <v-btn color="error" :loading="deletingUser" @click="deleteUser" data-test="submit-delete-user-btn">Delete user</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackbarMessage v-model="showSuccessSnackbar" data-test="success-message">{{successSnackMessage}}</SnackbarMessage>
    <SnackbarMessage v-model="showErrorSnackbar" data-test="success-message" error>{{errorSnackMessage}}</SnackbarMessage>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import UserForm from "@/components/UserForm.vue";
import SnackbarMessage from "@/components/SnackbarMessage.vue";
import User, { UserStatus } from "@/model/User";
import userService from "@/services/UserService";
import _ from "lodash";
import {roleDisplayName} from "@/services/view-helpers";

const PAGE_SIZE = 25;

export default Vue.extend({
  name: "AdminInvites",
  components: {UserForm, SnackbarMessage},
  data() {
    return {
      showUserForm: false,
      showSuccessSnackbar: false,
      successSnackMessage: '',
      showErrorSnackbar: false,
      errorSnackMessage: '',
      processingProfileIds: [] as number[],
      users: [] as User[],
      pageTokens: new Array<string|undefined>(),
      loadingUsers: true,
      error: '',
      lastNameFilter: '',
      lastUsedLastNameFilter: '',
      headers: [
        {text: '', value: 'actions', width: 1},
        {text: 'Name', value: 'name', class: 'text-no-wrap'},
        {text: 'Email', value: 'email', class: 'text-no-wrap'},
        {text: 'Phone', value: 'phoneNumber', class: 'text-no-wrap'},
        {text: 'Client', value: 'clientDisplayName', class: 'text-no-wrap'},
        {text: 'Role', value: 'roleName', class: 'text-no-wrap'},
      ],
      userToEditId: null as (number|null),
      userToDelete: null as (User|null),
      showDeleteConfirmation: false,
      deletingUser: false,
      deleteError: '',
    }
  },
  computed: {
    hasPrevPage(): boolean {
      return this.pageTokens.length >= 2;
    },
    hasNextPage(): boolean {
      return _.last(this.pageTokens) !== undefined;
    },
  },
  mounted() {
    this.lookupUsers(true);
  },
  watch: {
    lastNameFilter(val) {
      this.handleSearch(val, this.lookupUsers);
    }
  },
  methods: {
    confirmDeleteUser(item: User) {
      this.userToDelete = item;
      this.showDeleteConfirmation = true;
    },
    async deleteUser() {
      if (!this.userToDelete?.userProfile) {
        return;
      }
      try {
        this.deletingUser = true;
        await userService.deleteUser(this.userToDelete.userProfile.id);
        this.showDeleteConfirmation = false;
        this.successSnackMessage = 'User deleted successfully.';
        this.showSuccessSnackbar = true;
      } catch (e) {
        console.error('failed to delete the user:', e);
        this.deleteError = 'There was an error deleting the user.';
      } finally {
        this.deletingUser = false;
      }

      if (!this.deleteError) {
        await this.lookupUsers(true);
      }
    },
    async disableUser(item: User) {
      if (!item.userProfile) {
        return;
      }
      await this.setUserEnabled(item.userProfile.id, false);
    },
    async enableUser(item: User) {
      if (!item.userProfile) {
        return;
      }
      await this.setUserEnabled(item.userProfile.id, true);
    },
    async setUserEnabled(profileId: number, enabled: boolean) {
      try {
        this.processingProfileIds.push(profileId);
        await (enabled ? userService.enableUser(profileId) : userService.disableUser(profileId));
        const found = this.users.find(u => profileId === u.userProfile?.id);
        if (found) {
          found.enabled = enabled;
        }
        this.successSnackMessage = enabled ? 'User enabled successfully.' : 'User disabled successfully.'
        this.showSuccessSnackbar = true;
      } catch (e) {
        console.error(e);
        this.errorSnackMessage = enabled ? 'Failed to enable the user.' : 'Failed to disable the user.';
        this.showErrorSnackbar = true;
      } finally {
        this.processingProfileIds = this.processingProfileIds.filter(i => i !== profileId);
      }
    },
    fullName(item: User) {
      if (item.userProfile?.lastName) {
        return `${item.userProfile.lastName}, ${item.userProfile.firstName}`;
      } else if (item.lastName) {
        return `${item.lastName}, ${item.firstName}`;
      } else {
        return '';
      }
    },
    async lookupUsers(force = false) {
      if (force) {
        this.pageTokens = [];
        await this.lookupUsersFromApi();
      }
    },
    userSaved() {
      this.lookupUsers(true);
      this.successSnackMessage = 'User saved successfully!';
      this.showSuccessSnackbar = true;
      this.showUserForm = false;
      this.userToEditId = null;
    },
    handleSearch: _.debounce(function (term:string, searchFn: ()=>Promise<void>) {
      searchFn();
    }, 700),
    async nextPage() {
      await this.lookupUsersFromApi();
    },
    async prevPage() {
        this.pageTokens = _.dropRight(this.pageTokens, 2);
        await this.lookupUsersFromApi();
    },
    async lookupUsersFromApi() {
      try {
        this.loadingUsers = true;
        const userPage = await userService.listInvitesOnly(PAGE_SIZE, _.last(this.pageTokens));
        this.users = userPage.users;
        this.pageTokens = [...this.pageTokens, userPage.pageToken];
      } catch (e) {
        this.error = 'There was an error loading the users';
        console.error('failed to get the next page of users', e);
      } finally {
        this.loadingUsers = false;
      }
    },
    async showEditForm(user: User) {
      if (user.userProfile) {
        this.userToEditId = user.userProfile.id;
        this.showUserForm = true;
      }
    },
    hasNotLoggedInForFirstTime(user: User): boolean {
      return user.status === UserStatus.FORCE_CHANGE_PASSWORD;
    },
    async resendEmail(user: User) {
      if (user.userProfile) {
        const responseStatus = await userService.resendUserInviteEmail(user.userProfile.id);
        if (responseStatus === 200) {
          this.successSnackMessage = 'Invite Email Re-Sent To User!';
          this.showSuccessSnackbar = true;
        } else {
          this.error = 'There was an error sending invite email';
          console.error('failed to resend invite email');
        }
      }
    },
    roleDisplayName,
  }
});
</script>

<style scoped>
  .search-field {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 400px;
    min-width: 300px;
  }
  a {
    cursor: pointer;
  }
</style>

<style>
  .disabled-user td {
    color: gray;
  }
</style>
