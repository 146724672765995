<template>
  <div>
    <div v-if="error" class="error--text">Failure loading access groups</div>
    <div v-else class="d-flex flex-wrap">
      <div class="mr-5" v-for="item in items" :key="item.value">
        <div class="group-option">
          <v-checkbox class="my-0" hide-details dense :label="item.text" color="secondary" :input-value="isSelected(item)" @change="handleItemChange(item, $event)"/>
          <div class="ml-8 item-description">{{item.description}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, {PropType} from "vue";
import Role from "../model/Role";
import Group from "../model/Group";
import {DataLoadStatus} from "@/store";

interface ItemType {
  value: number,
  text: string,
  description: string,
}

export default Vue.extend({
  name: "UserGroupsSelect",
  props: {
    value: Array as PropType<number[]>,
    role: [Object, String] as PropType<Role>,
    disabled: Boolean,
  },
  computed: {
    groupsState(): { values: Group[], status: DataLoadStatus } {
      return this.$store.state.groups;
    },
    items(): ItemType[] {
      return this.groupsState.values
          .filter(g => g.allowedRole === this.role)
          .map(g => ({text: g.title, value: g.id, description: g.description}));
    },
    loading(): boolean {
      return this.groupsState.status === DataLoadStatus.LOADING;
    },
    error(): string {
      return this.groupsState.status === DataLoadStatus.ERROR ? 'Failed to load groups' : '';
    }
  },
  mounted() {
    this.$store.dispatch('loadGroupsIfNeeded');
  },
  methods: {
    selectDefaultForRole() {
      const defaultGroups = this.groupsState.values.filter(g => g.isDefault && g.allowedRole === this.role);
      this.$emit('input', defaultGroups.map(g => g.id));
    },
    isSelected(item: ItemType): boolean {
      return this.value.includes(item.value);
    },
    handleItemChange(item: ItemType, newVal: boolean) {
      if (newVal) {
        this.$emit('input', [...this.value, item.value]);
      } else {
        this.$emit('input', this.value.filter(v => v !== item.value));
      }
    }
  }
})
</script>

<style scoped>
  .item-description {
    font-size: .9em;
    color: gray;
  }
</style>
