<template>
  <div class="client-user-list-dialog">
    <SnackbarMessage v-model="showCopied" data-test="success-message">{{this.users.length}} email addresses copied.</SnackbarMessage>
    <v-dialog :value="value" @input="$emit('input', $event)">
      <v-card v-if="client">
        <v-alert v-if="error" type="error" rounded="0" dismissible data-test="error-alert">{{error}}</v-alert>
        <v-card-title>Users</v-card-title>
        <v-card-subtitle>{{client.displayName}}</v-card-subtitle>
        <v-card-text>
          <v-data-table
              dense
              :loading="loadingUsers"
              :headers="headers"
              :items="users"
              hide-default-footer
              disable-sort
          >
            <template v-slot:[`item.name`]="{item}">
              <div data-test="profile-name">
                {{fullName(item)}}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="copyEmails">Copy email addresses</v-btn>
          <v-btn @click="$emit('input', false)">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue, {PropType} from "vue";
import {Client} from "@/model/Client";
import UserProfile from "@/model/UserProfile";
import userService from "@/services/UserService";
import SnackbarMessage from "@/components/SnackbarMessage.vue";

export default Vue.extend({
  name: "ClientUserListDialog",
  components: {SnackbarMessage},
  props: {
    value: Boolean,
    client: Object as PropType<Client>
  },
  data() {
    return {
      loadingUsers: false,
      headers: [
        {text: 'Name', value: 'name', class: 'text-no-wrap'},
        {text: 'Email', value: 'email', class: 'text-no-wrap'},
        {text: 'Phone', value: 'phoneNumber', class: 'text-no-wrap'},
      ],
      users: new Array<UserProfile>(),
      error: '',
      showCopied: false,
    }
  },
  mounted() {
    this.lookupUsers();
  },
  watch: {
    client() {
      this.lookupUsers();
    }
  },
  methods: {
    async lookupUsers() {
      if (!this.client) {
        this.users = [];
        return;
      }
      try {
        this.loadingUsers = true;
        this.users = await userService.getUserProfiles(undefined, this.client.id);
      } catch (e) {
        console.error('failed to lookup users:', e);
        this.error = 'Failed to lookup the users';
      } finally {
        this.loadingUsers = false;
      }
    },
    fullName(user: UserProfile) {
      if (user.firstName || user.lastName) {
        return `${user.firstName} ${user.lastName}`;
      } else {
        return "--";
      }
    },
    async copyEmails() {
      const emails = this.users.map(u => u.email).join(',');
      await navigator.clipboard.writeText(emails);
      this.showCopied = true;
    }
  }
});
</script>

<style scoped>
</style>
