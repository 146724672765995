import AuditInfo from "@/model/AuditInfo";

export enum RoutingType {
    TO_CLIENT = 'to-client',
    FROM_CLIENT = 'from-client'
}

export interface SecureFileTransfer {
    id: number,
    uuid: string,
    clientId: number,
    routingType: RoutingType,
    filename: string,
    description: string,
    isRead: boolean,
    readBy: AuditInfo|null,
    readAt: Date|null,
    uploadVerified: boolean,
    createdBy: AuditInfo,
    createdAt: Date,
    updatedBy: AuditInfo,
    updatedAt: Date,

    clientDisplayName?: (string|null),
}

export interface SecureFileTransferForCreate extends Omit<SecureFileTransfer, ('id'|'uuid'|'isRead'|'readBy'|'readAt'|'uploadVerified'|'createdBy'|'createdAt'|'updatedBy'|'updatedAt'|'filename')> {
    filenames: string[]
}
