export enum AuthStep {
    LOGIN,
    MFA,
    RESET_PASSWORD,
    RESET_PASSWORD_VERIFICATION,
    COMPLETE_PROFILE,
    SIGNED_IN
}

export interface CodeDeliveryDetails {
    AttributeName: string,
    DeliveryMedium: string,
    Destination: string
    DestinationFull: string,
}

export interface ChallengeParam {
    requiredAttributes?: string[],
    CODE_DELIVERY_DESTINATION?: string,
}

export interface AuthResponse {
    challengeName?: string,
    challengeParam?: ChallengeParam,
    signInUserSession?: {[key: string]: string},
    CodeDeliveryDetails?: CodeDeliveryDetails,
}
