<template>
  <div class="report-precedence-select">
    <v-select
        :value="value"
        @input="$emit('input', $event)"
        :items="items"
        :dense="dense"
        label="Precedence"
        outlined
        :loading="loading"
        :rules="rules"
        name="role"
        data-test="report-precedence-select"
    ></v-select>
    <SnackbarMessage error v-model="showError">{{ error }}</SnackbarMessage>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import SnackbarMessage from "./SnackbarMessage.vue";
import ReportPrecedence from "@/model/ReportPrecedence";
import {DataLoadStatus} from "@/store";

export default Vue.extend({
  name: "ReportPrecedenceSelect",
  components: {SnackbarMessage},
  props: {
    value: Number,
    rules: Array,
    dense: Boolean
  },
  data() {
    return {
      showError: false,
    }
  },
  computed: {
    reportPrecedencesState(): { values: ReportPrecedence[], status: DataLoadStatus } {
      return this.$store.state.reportPrecedence;
    },
    items(): { text: string, value: number }[] {
      return this.reportPrecedencesState.values.map(r => ({text: r.name, value: r.id}));
    },
    loading(): boolean {
      return this.reportPrecedencesState.status === DataLoadStatus.LOADING;
    },
    error(): string {
      return this.reportPrecedencesState.status === DataLoadStatus.ERROR ? 'Failed to load report precedences' : '';
    }
  },
  watch: {
    error(newVal, oldVal) {
      if (!oldVal && !!newVal) {
        this.showError = true;
      }
    }
  },
  mounted() {
    if (this.reportPrecedencesState.status === DataLoadStatus.NOT_LOADED) {
      this.$store.dispatch('loadReportPrecedences');
    }
  },
  methods: {}
});
</script>

<style scoped>

</style>
